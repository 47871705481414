import React from 'react';
import { Tune, PowerSettingsNew, AccountCircle } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import { doLogout } from '../../../../utils/auth';


const AccountDropdown = () => {
  const logout = React.useCallback((event) => {
    event.preventDefault();
    doLogout();
  }, []);

  return (
    <div className="nav-item dropdown mobile-header-nav-settings">
      <Link className="nav-link dropdown-toggle" to="/account">
        <AccountCircle />
      </Link>
      <div aria-labelledby="navbarDropdown" className="dropdown-menu">
        <div className="mobile-header-nav-settings__links">
          <Link to="/account">
            <Tune fontSize="inherit" />
            <span>Account Settings</span>
          </Link>
        </div>
        <div className="mobile-header-nav-settings__links">
          <a
            title="Logout"
            to="#"
            onClick={logout}
          >
            <PowerSettingsNew fontSize="inherit" />
            <span>Sign Out</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default AccountDropdown;

import React from 'react';
import { Email } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import withUnreadConversationsCount from '../../../HOC/withUnreadConversationsCount';


@withUnreadConversationsCount
class HeaderInboxLink extends React.Component {

  getPointerClass = () => {
    const { location } = this.props;
    if (location?.pathname === '/chat') {
      return 'none';
    }

    return 'all';
  };

  render() {
    const { numOfUnreadConversations: num } = this.props;
    let className = 'mobile-header-nav-private-chat';
    if (num > 0) {
      className += ' mobile-header-nav-private-chat--with-unread-conversations';
    }

    return (
      <Link style={{ pointerEvents: this.getPointerClass() }} title="Private Chat" to="/chat">
        <div className={className}>
          <Email fontSize="large" />
          {num > 0 && (
            <span
              className="mobile-header-nav-private-chat__unread-count"
              title={`${num} unread conversation${num > 1 ? 's' : ''}`}
            >
              {num}
            </span>
          )}
        </div>
      </Link>
    );
  }
}

export default HeaderInboxLink;

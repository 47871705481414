import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { UserContext } from '../context';


function ProtectedRoute({ component: Component, ...rest }) {
  return (
    <UserContext.Consumer>
      {(value) => (<Route
        {...rest}
        render={(props) =>
          value.authenticated ? (
            <Component {...props} user={value} />
          ) : (
            <Redirect
              to={{
                pathname: '/account/login',
                state: { from: props.location },
              }}
            />
          )
        }
      />)}
    </UserContext.Consumer>
  );
}

export default ProtectedRoute;

import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { UserContext } from '../context';
import { isNudeDomain } from './helpers';


const UserContextRoute = ({ component: Component, activeCampaign, allActiveCampaigns, ...rest }) => {
  const user = React.useContext(UserContext);
  if (!user.authenticated && isNudeDomain) {
    return <Redirect to={{ pathname: '/tour' }} />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Component
          {...props}
          activeCampaign={activeCampaign || {}}
          allActiveCampaigns={allActiveCampaigns || []}
          user={user}
        />
      )}
    />
  );
};

export default UserContextRoute;

import React, { useCallback, useContext, useState } from 'react';
import styles from './EmailChangeModal.module.css';
import EmailChangeModalTop from '../../assets/EmailChangeModal/girl-images.png';
import Modal from 'simple-react-modal';
import { observer } from 'mobx-react';
import ModalStore from '../../store/ModalStore';
import callApi from '../../utils/callApi';
import { TEMPTED_ACCOUNTS_URL } from '../../constants/apiUrls/apiUrls';
import { RootStoreContext } from '../../context';



function NicknameChangeNotifyModal({ show }) {
  const rootContext = useContext(RootStoreContext);
  const { loggedInUser } = rootContext;
  const [data, setData] = useState('');
  const [nicknameSet, setNicknameSet] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [disableInput, setDisableInput] = useState(true);
  const closeModal = useCallback(() => {
    if (!nicknameSet) {
      return;
    }
    ModalStore.setShowNicknameCollectModal(false);
  }, []);

  const handleErrorOnSubmit = (err) => {
    if (err.response.status === 409) {
      setErrorMessage('This nickname is already in use. Please try a different one.');
      setDisableInput(false);
    }
    if (err.response.status === 400) {
      setErrorMessage('The nickname is invalid. Please try a different one');
      setDisableInput(false);
    }
  }

  const handleNicknameChanges = useCallback(() => {
    if (loggedInUser?.account?.nicknameUpdateCounter === 0 && !loggedInUser?.account?.anchor) {
      setDisableInput(true);
      const payload = { nickname: data };
      payload.username = sessionStorage.getItem('username');
      callApi(TEMPTED_ACCOUNTS_URL, 'PUT', false, payload, true, true, true, true)
        .then(() => {
          setNicknameSet(true);
          window.location.reload();
        })
        .catch(handleErrorOnSubmit);
    }
  }, [loggedInUser?.account?.nicknameUpdateCounter, loggedInUser?.account?.anchor, data]);

  const onInputChange = useCallback((event) => {
    setErrorMessage('');
    setDisableInput(false);
    const input = event.target.value;
    setData(input.trim());
  }, []);

  return (
    <Modal
      show={show}
      containerClassName={styles.modalContainer}
      closeOnOuterClick={false}
      onClose={closeModal}
    >
      <div className={styles.container}>
        <img src={EmailChangeModalTop} alt="NakedNewsGirls" />
        <h3>You&apos;re almost a member</h3>
        <p className={styles.disclaimer}>Almost there! In order to enjoy your benefits please provide your Nickname.</p>
        <div className={styles.inputContainer}>
          <input
            type="text"
            placeholder="Enter your Nickname"
            maxLength={30}
            onChange={onInputChange}
            value={data}
          />
          <p className={styles.error}>{errorMessage}</p>
        </div>
        <button
          className={styles.submitButton}
          disabled={data?.length < 1 || disableInput}
          onClick={handleNicknameChanges}
        >
          SUBMIT
        </button>
        <small>
          <p className={styles.disclaimer}>
            By continuing, you agree to our&nbsp;
            <a target="_blank" className={styles.link} href={"/terms_of_service"}>Terms</a>.
            Learn how we collect, use, and share your data in our&nbsp;
            <a target="_blank" className={styles.link} href={"/privacy_policy"}>Privacy Policy</a>,
            and how we use Cookies in our&nbsp;
            <a target="_blank" className={styles.link} href={"/cookie-policy"}>Cookie Policy</a>.
          </p>
        </small>
      </div>
    </Modal >
  );
}

export default observer(NicknameChangeNotifyModal);

const MAX_CHUNK_SIZE = 8000000;

export const splitFileIntoChunks = (videoFile) => {
  const totalChunks = Math.floor(videoFile?.size / MAX_CHUNK_SIZE) + 1;
  const fileSplit = [];

  for (let i = 0; i < totalChunks; i++) {
    const start = i * MAX_CHUNK_SIZE;
    const end = (i + 1) * MAX_CHUNK_SIZE;
    const chunk = i < totalChunks ? videoFile.slice(start, end) : videoFile.slice(start);
    fileSplit.push(chunk);
  }

  return fileSplit;
}

export const fileTypeIsValid = (fileType, typeToMatch) => fileType?.startsWith(typeToMatch);

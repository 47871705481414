import React from 'react';
import { observer } from 'mobx-react';
import { isAndroid, isChrome } from 'react-device-detect';

import { RootStoreContext } from '../../context';

import LastNotificationPreview from './LastNotificationPreview';


@observer
class Notifications extends React.Component {

  static contextType = RootStoreContext;

  state = { desktopNotifications: false };

  async componentDidMount() {
    if ('Notification' in window && !(isAndroid && isChrome)) {
      if (Notification.permission === 'granted') {
        this.setState({ desktopNotifications: true });
      } else if (Notification.permission === 'default' && window.location.pathname !== '/') {
        const status = await Notification.requestPermission();
        if (status === 'granted') {
          this.setState({ desktopNotifications: true });
        }
      }
    }
  }

  dismissLastNotification = () => {
    this.context.uiStore.dismissLastNotification();
  };

  render() {
    const { uiStore: { notificationsDerivation } } = this.context;
    const { user } = this.props;
    const { visible, data } = notificationsDerivation;
    const { desktopNotifications } = this.state;

    return visible && (
      <LastNotificationPreview
        desktopNotifications={desktopNotifications}
        dismiss={this.dismissLastNotification}
        notification={data[data.length - 1]}
        user={user}
      />
    );
  }
}

export default Notifications;

import React from 'react';


class Unauth extends React.Component {
  render() {
    const { alreadyMember, openLoginModal, hideHeaderLoginButton, isAffiliatePage } = this.props;

    return (
      <>
        {!hideHeaderLoginButton && (
          <li className="navbar-login-link ">
            <a
              href=""
              role="button"
              onClick={openLoginModal}
            >
              Login
            </a>
          </li>
        )}
        {!alreadyMember && !isAffiliatePage && (
          <li className="pull-right">
            <a
              className="purchase cta-join"
              href="/account/purchase"
            >
              Join Now &gt;&gt;
            </a>
          </li>
        )}
      </>
    );
  }
}

export default Unauth;

import PrivateMessagingStore from './fragments/PrivateMessaging/PrivateMessagingStore';
import UIStore from './fragments/UI/UIStore';
import TippingStore from './TippingStore';

class RootStore {
  constructor () {
    this.privateMessagingStore = new PrivateMessagingStore(this);
    this.uiStore = new UIStore(this);
    this.tippingStore = new TippingStore(this);
  }
  loggedInUser = null;
}

export default new RootStore();

import { action, observable } from "mobx";


class ModalStore {
  @observable showEmailModal = false;

  @observable showNicknameCollectModal = false;

  @action
  setShowEmailModal = (value) => {
    this.showEmailModal = value;
  }

  @action
  setShowNicknameCollectModal = (value) => {
    this.showNicknameCollectModal = value;
  }
}

export default new ModalStore();

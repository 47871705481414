import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { UserContext } from '../../context';
import FullLoader from '../Loaders/FullLoader';
import { isNudeDomain } from '../../utils/helpers';


const AnchorPage = React.lazy(() => import('../../pages/AnchorPage/AnchorPage'));
const BlogPage = React.lazy(() => import('../../pages/Blogs/Blog/Blog'));
const ArchivesPage = React.lazy(() => import('../../pages/Archives/Archives'));


const RoutesWithSlugPath = ({ activeCampaign, anchorPortalCampaign }) => {
  const user = React.useContext(UserContext);

  return (
    <Route
      exact={true}
      path="/:slug*"
      render={({ match, location, history }) => {
        if (!user.authenticated && isNudeDomain) {
          return <Redirect to={{ pathname: '/tour' }} />;
        }
        if ('live' === match.params.slug) {
          return (
            <React.Suspense fallback={<FullLoader />}>
              <AnchorPage
                key="live1"
                activeCampaign={activeCampaign}
                anchorPortalCampaign={anchorPortalCampaign}
                history={history}
                isLive={true}
                location={location}
                slug={match.params.slug}
                user={user}
              />
            </React.Suspense>
          );
        }
        if (/(^naked-news-anchor-)/.test(match.params.slug)) {
          return (
            <React.Suspense fallback={<FullLoader />}>
              <AnchorPage
                key={match.params.slug}
                activeCampaign={activeCampaign}
                anchorPortalCampaign={anchorPortalCampaign}
                history={history}
                location={location}
                slug={match.params.slug}
                user={user}
              />
            </React.Suspense>
          );
        }
        const pathname = `${location.pathname}${location.search}`;

        location.pathname = pathname;

        if (/(---\d+)/.test(pathname)) {
          return (
            <React.Suspense fallback={<FullLoader />}>
              <BlogPage location={location} slug={match.params.slug} user={user} />
            </React.Suspense>
          );
        }
        if (
          /(-w\d{1,})/.test(match.params.slug) ||
          /(-v(\d)*)/.test(match.params.slug) ||
          /(\/nude-program)/.test(match.params.slug) ||
          /(\d\d\d\d\/\d\d)/.test(match.params.slug)
        ) {
          return (
            <React.Suspense fallback={<FullLoader />}>
              <ArchivesPage
                history={history}
                location={location}
                slug={match.params.slug}
                user={user}
              />
            </React.Suspense>
          );
        }

        return (
          <React.Suspense fallback={<FullLoader />}>
            <ArchivesPage
              history={history}
              location={location}
              match={match}
              user={user}
            />
          </React.Suspense>
        );
      }}
    />
  );
};

export default RoutesWithSlugPath;

import { observable, action, computed } from 'mobx';

import * as notificationsActions from './actions/Notifications';
import * as unreadConversationsActions from './actions/UnreadConversations';

import * as notificationsComputed from './computed/Notifications';

class UIStore {
  constructor (rootStore) {
    this.rootStore = rootStore;
  }

  @observable notifications = {
    canAddEvent: false,
    data: [],
    opened: false
  };
  @observable shouldGetNumOfConversationsWithUnreadMessages = false;
  @observable numOfConversationsWithUnreadMessages = 0;

  @computed get notificationsDerivation () {
    return notificationsComputed.notificationsDerivation.call(this)
  }

  @action notify (event) {
    if (event.content.alphaTemptation) {
      notificationsActions.notify.call(this, event);
    }
  }

  @action dismissLastNotification () {
    notificationsActions.dismissLastNotification.call(this)
  }

  @action getNumOfConversationsWithUnreadMessages () {
    unreadConversationsActions.getNumOfConversationsWithUnreadMessages.call(this)
  }
}

export default UIStore;
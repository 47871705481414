import React from 'react';


function EmailValidationError(props) {
  const { authenticated, emailErrorMessage } = props;

  if (authenticated || emailErrorMessage.length < 1) {
    return null;
  }

  return (
    <h4 className="error-text text-center">
      {emailErrorMessage}
    </h4>
  );
}

export default EmailValidationError;

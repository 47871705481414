import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

/**
 *
 * @param {number} timestamp - unix timestamp
 *
 * @returns {string} - example 12:32 (always two digits for both hours and minutes)
 */
export const parseHoursAndMinutesFromTimestamp = (timestamp) => {
  const date = dayjs(timestamp);
  let hours = date.hour();
  let minutes = date.minute();
  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  return `${hours}:${minutes}`;
};

/**
 *
 * @param {number} referenceTime - milliseconds from which the relative
 * time is calculated
 *
 * @returns {string} - see moment.js for documentation
 */

dayjs.extend(calendar);
dayjs.extend(isSameOrBefore);

export const parseTimeRelativeToReference = (referenceTime) => {
  const momentObj = dayjs(referenceTime);
  if (momentObj.isValid()) {
    return momentObj.calendar(null, {
      sameDay: '[Today]',
      lastDay: '[Yesterday]',
      lastWeek: 'MM/DD/YYYY',
      sameElse: 'MM/DD/YYYY',
    });
  }

  return '';

};

export const differenceIsGreaterOrEqualToGivenDays = (date1, date2, days) => {
  const firstDate = dayjs(date1);
  const currentDate = dayjs(date2);
  const diffInDays = firstDate.diff(currentDate, 'days');

  return diffInDays >= days;
};


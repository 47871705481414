import React from 'react';
import { Link } from 'react-router-dom';
import validator from 'validator';


export const NakedLink = ({ to, ...rest }) => validator.isURL(to) ? (
  <a href={to} {...rest} />
) : (
  <Link to={to} {...rest} />
);


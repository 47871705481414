export function notify(event) {
  if (this.notifications.canAddEvent) {
    this.notifications.data.push(event);
    this.notifications.opened = true;
  } else {
    this.notifications.canAddEvent = true;
  }
}

export function dismissLastNotification() {
  this.notifications.data.pop();
  this.notifications.opened = false;
}

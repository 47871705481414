import { TEMPTED_WEB_CHAT_AUDIO_MESSAGE, TEMPTED_WEB_CHAT_MESSAGING } from '../../../../constants/apiUrls/apiUrls';
import callApi from '../../../../utils/callApi';


export const sendMessage = (conversationName, payload) =>
  callApi(
    TEMPTED_WEB_CHAT_MESSAGING.replace('{conversationName}', conversationName),
    'POST',
    {},
    payload,
    {},
    true,
    true,
    true,
  );

export const sendFanAudioMessage = (conversationName, payload) =>
  callApi(TEMPTED_WEB_CHAT_AUDIO_MESSAGE.replace('{conversationName}', conversationName), 'POST', {}, payload, {}, true, true, true);

export function sendAudioMessage(payload) {
  this.conversationSession.session.createResourceEntry(payload.audioFile, 'wav', (error, result) => {
    if (!error) {
      if (result.status === 'UPLOADED') {
        this.conversationSession.session.writeResourceMessage(result.resource, undefined, (errorWriteMessage, success) => {
          if (errorWriteMessage) {
            throw errorWriteMessage;
          } else {
            console.log(success);
          }
        });
      } else {
        throw result;
      }
    } else {
      throw error;
    }
  });
}

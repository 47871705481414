import React from 'react';
import Loader from 'react-simple-loading';


function FullLoader() {
  return (
    <div
      style={{
        minHeight: '100vh',
        minWidth: '100vw',
        display: 'flex',
        position: 'absolute',
        zIndex: 1000000,
        top: 0,
        left: 0,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#0c0921',
      }}
    >
      <Loader color="white" />
    </div>);
}

export default FullLoader;

import React from 'react';

import HeaderInboxLink from './HeaderInboxLink';
import HeaderAccountDropdown from './HeaderAccountDropdown';


const Auth = ({
  userPremiumExpired,
  isAnchor,
  showMobileHeader,
  user,
  location,
}) => {
  const { user: { account: { anchorSubscriptions, anchor, blockFanzoneAccess } }, features } = user || {};
  const userHasFanzoneAccess = !blockFanzoneAccess;
  const shouldDisplayInboxIcon = userHasFanzoneAccess && (anchorSubscriptions.length > 0 || !!anchor);

  return (
    <>
      <li className="hidden-xs hidden-sm">
        <div className="navbar-account-links">
          {shouldDisplayInboxIcon && <HeaderInboxLink location={location} user={user} />}
          <HeaderAccountDropdown />
        </div>
      </li>
      {userPremiumExpired && !isAnchor && (
        <li
          className="pull-right"
          style={{ marginRight: showMobileHeader ? 'auto' : '0px' }}
        >
          <a
            className="purchase cta-join upgrade"
            href="/account/purchase"
          >
            Upgrade &gt;&gt;
          </a>
        </li>
      )}
    </>
  );
};

export default Auth;

import React from 'react';
import { Snackbar, SnackbarContent, IconButton, Avatar } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';

import './css/Notifications.css';
import { observer } from 'mobx-react';

import { RootStoreContext } from '../../context';
import AnchorStore from '../../store/AnchorStore';
import { buildAnchorLink } from '../../utils/helpers';


@observer
class LastNotificationPreview extends React.Component {
  static contextType = RootStoreContext;
  componentDidMount() {
    this.notify();
    AnchorStore.setAnchors();
  }

  componentDidUpdate() {
    this.notify();
  }

  notify() {
    const { desktopNotifications, notification, user } = this.props;
    if (notification.type === 'isStaticRealityShowCam') {
      return null;
    }
    if (notification.type === 'anchorStartingBroadcast') {
      if (desktopNotifications) {
        const n = new Notification(
          'Anchor is live',
          { body: `${notification.anchorName} has started a live broadcast!` }
        );
        n.onclick = this.onNotificationClick;
      }
    }
  }

  onNotificationClick = (event) => {
    const { notification } = this.props;
    event.preventDefault();
    window.location.href = `${window.location.origin}${notification.anchorPath}`;
  };

  getAnchorUrl = (anchorId) => {
    const anchorData = AnchorStore.getAnchorData(anchorId);

    return buildAnchorLink(anchorData);
  };

  render() {
    const { notification, dismiss } = this.props;
    const {
      anchorId,
      isPublic,
      type,
      temptationName,
      anchorName,
      spotlightedTemptationName,
      temptationId,
    } = notification || {};
    let message = '';
    let snackbarContentMessage;
    let snackbarContentMessageText;
    const notificationAnchorId = anchorId || temptationId;
    const anchorPageURL = this.getAnchorUrl(notificationAnchorId);

    if (type === 'isStaticRealityShowCam' || type === 'temptationStoppedSpotlighting') {
      return null;
    }

    if (type === 'anchorStartingBroadcast' && !isPublic) {
      message = `${anchorName} has started a live broadcast`;
      snackbarContentMessageText = (
        <a href={anchorPageURL} id="global-notification-message">{message}!</a>
      );
    }
    if (type === 'anchorStartingBroadcast' && isPublic) {
      message = `${anchorName} has just started a FREE live show`;
      snackbarContentMessageText = (
        <a href={anchorPageURL} id="global-notification-message">{message}!</a>
      );
    }
    if (type === 'temptationStartedSpotlighting') {
      message = `${temptationName} has started spotlighting ${spotlightedTemptationName}! Watch now!`;
      snackbarContentMessageText = (
        <a href={this.getAnchorUrl(notificationAnchorId)} id="global-notification-message">{message}</a>
      );
    }

    if (type === 'anchorStoppingBroadcast') {
      message = `Do you want to thank ${anchorName} for her live show`;
      snackbarContentMessageText = (
        <span id="global-notification-message">{message}
          <button
            className="send-tip-button"
            onClick={() => {
              this.context.tippingStore.setTippingModal({ name: anchorName, id: notificationAnchorId });
            }}
          >
            Send tip
          </button>!
        </span>
      );
    }
    if (type === 'temptationJoinedVPS') {
      const partyShowInitiator = (notification.initiatorTemptationName || notification.initiatorTemptationNickname);
      const anchorName = (notification.temptationNickname || notification.temptationName);
      message = `${anchorName} has just joined ${partyShowInitiator}'s Live show`;
      snackbarContentMessageText = (
        <a href={this.getAnchorUrl(notification?.temptationId)} id="global-notification-message">{message}!</a>
      );
    }
    if (notification.anchorNotificationImage || notification.temptationNotificationImage) {
      snackbarContentMessage = (
        <div id="global-notification-with-image-container">
          <Avatar
            src={notification.anchorNotificationImage || notification.temptationNotificationImage}
            style={{ marginRight: '10px' }}
          />
          {snackbarContentMessageText}
        </div>
      );
    } else {
      snackbarContentMessage = snackbarContentMessageText;
    }

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={true}
      >
        <SnackbarContent
          action={[
            <IconButton key="close" color="inherit" onClick={dismiss}>
              <CloseOutlined fontSize="large" />
            </IconButton>,
          ]}
          message={snackbarContentMessage}
          style={{ backgroundColor: '#0064E5' }}
        />
      </Snackbar>
    );
  }
}

export default LastNotificationPreview;

import { observable, action } from 'mobx';
import Axios from 'axios';

import { FAN_VIDEO_UPLOADED_PATCH, TEMPTED_AUDIO_MESSAGE_PASS_AVAILABILITY, TEMPTED_AUDIO_MESSAGE_PRICE } from '../constants/apiUrls/apiUrls';
import callApi from '../utils/callApi';
import { fileTypeIsValid, splitFileIntoChunks } from '../utils/fileUploadUtils';

import NotificationStore from './NotificationStore';


const NOTIFICATION_MESSAGES = {
  UPLOAD_ERROR: 'Error uploading video file. Please try again!',
  SUCCESS: 'Video was uploaded successfully.',
  FILE_TYPE_ERROR: 'Please ensure your video is in MP4 format and does not exceed 1GB',
};

class PaidFeatureStore {
  @observable paidFeaturePrice = null;
  @observable availableFeatures = {
    canSendAudioMessages: false,
    canSendVideos: false,
  };

  @observable paidFeaturePassIsAvailable = null;
  @observable selectedAnchorName = null;
  @observable selectedAnchorId = null;
  @observable videoFile = null;
  @observable error = null;
  @observable videoSrc = null;
  @observable showUploadUi = false;
  @observable uploadProgress = 0;
  @observable isLoading = false;
  @observable userId = null;
  @observable initiateUploadAutomatically = false;

  @action setIsLoading = (value) => {
    this.isLoading = value;
  };

  @action setSelectedAnchorName = (anchor) => {
    this.selectedAnchorName = anchor?.name || anchor?.username;
  };

  @action setSelectedAnchorId = (id) => {
    this.selectedAnchorId = id;
  };

  getUserId = (user) => {
    return this.userId || user?.user?.account?.temptedId;
  }

  @action setUserId = (id) => {
    if (this.userId) {
      return;
    }
    this.userId = id;
  };

  @action setInitiateUploadAutomatically = (value) => {
    this.initiateUploadAutomatically = false;
  }

  @action
  checkIfUserHasAudioMessagePass = (user, anchorData, successHandler, initiateUploadAutomatically) => {
    this.setSelectedAnchorName(anchorData);
    this.setSelectedAnchorId(anchorData?.id);
    this.setUserId(user?.user?.account?.temptedId);
    this.initiateUploadAutomatically = initiateUploadAutomatically;
    const url = TEMPTED_AUDIO_MESSAGE_PASS_AVAILABILITY.
      replace('{userId}', this.getUserId(user)).
      replace('{anchorId}', anchorData?.id);
    callApi(url, 'GET', {}, {}, {}, true, true, true).
      then((res) => {
        if (res.status === 200) {
          this.paidFeaturePassIsAvailable = true;
          this.isLoading = false;
          if (typeof successHandler === 'function') {
            successHandler(this.paidFeaturePassIsAvailable);
          }
        }
      }).
      catch((err) => {
        this.paidFeaturePassIsAvailable = false;
        this.isLoading = false;
      });
  };

  @action
  handlePaidFeaturePassPaymentEvent = (temptationId) => {
    if (temptationId === this.selectedAnchorId) {
      this.checkIfUserHasAudioMessagePass(null, { id: temptationId }, null, true);
    }
  }

  @action
  getAnchorAudioMessageFeaturePrice = (anchorData) => {
    callApi(TEMPTED_AUDIO_MESSAGE_PRICE.replace('{anchorId}', anchorData?.id), 'GET', {}, {}, {}, true, true, true).
      then((res) => {
        this.availableFeatures.canSendAudioMessages = res?.data?.audioMessagesEnabled;
        this.availableFeatures.canSendVideos = res?.data?.videoMessagesEnabled;
        this.paidFeaturePrice = res.data.price;
      }).catch((err) => {

      });
  };

  @action
  setVideoFile = (file) => {
    this.videoFile = file;
  };

  @action
  setError = (error) => {
    this.error = error;
  };

  @action
  setVideoSrc = (src) => {
    this.videoSrc = src;
  };

  @action
  handleModalClose = () => {
    this.setShowUploadUi(false);
    this.setVideoFile(null);
    this.setVideoSrc(null);
  };

  @action
  onFileSelected = (acceptedFiles) => {
    this.setError(null);
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const videoUrl = URL.createObjectURL(file);
      if (!fileTypeIsValid(file?.type, 'video')) {
        this.setError(NOTIFICATION_MESSAGES.FILE_TYPE_ERROR);

        return;
      }
      this.setVideoFile(file);
      this.setVideoSrc(videoUrl);
    }
  };

  @action
  setShowUploadUi = (value) => {
    this.showUploadUi = value;
  };

  @action
  setUploadProgress = (value) => {
    this.uploadProgress = value;
  };

  @action
  uploadChunk = (payload, url, index, totalChunks) => {
    const blankInstance = Axios.create();

    return blankInstance.put(url, payload, {
      headers: { 'Content-Type': 'application/octet-stream' },
      onUploadProgress: (progressEvent) => {
        let percentCompleted;
        if (totalChunks > 1) {
          percentCompleted = Math.round(((index + 1) / totalChunks) * 100);
        } else {
          const { loaded, total } = progressEvent || {};
          percentCompleted = Math.round((loaded / total) * 100);
        }

        this.setUploadProgress(percentCompleted);
      },
    });
  };

  @action sendUploadCompletionRequest = (uploadId, videoId, activeConversationName, userId) => {
    const url = FAN_VIDEO_UPLOADED_PATCH.
      replace('{userId}', userId).
      replace('{videoId}', videoId).
      replace('{conversationId}', activeConversationName);
    callApi(url, 'PATCH', {}, { status: 'UPLOADED', uploadId }, {}, true, true, true).then((res) => {
      if (res?.data?.status === 'DONE') {
        this.setUploadProgress(0);
        this.setIsLoading(false);
        NotificationStore.success(NOTIFICATION_MESSAGES.SUCCESS);
      }
    }).catch(() => {
      this.setIsLoading(false);
      this.handleModalClose();
      NotificationStore.error(NOTIFICATION_MESSAGES.UPLOAD_ERROR);
    });
  };

  @action clearUploadedContent = () => {
    this.setVideoFile(null);
    this.setVideoSrc(null);
  };

  @action handleContentUpload = async (data, activeConversationName, userId, allChunks) => {
    const { temporaryUrlList, uploadId, videoId, initialUploadUrl } = data || {};
    this.setShowUploadUi(false);

    if (temporaryUrlList?.length) {
      for (let index = 0; index < temporaryUrlList.length; index++) {
        try {
          await this.uploadChunk(allChunks?.[index], temporaryUrlList[index], index, allChunks?.length);
        } catch (e) {
          this.clearUploadedContent();
          NotificationStore.error(NOTIFICATION_MESSAGES.UPLOAD_ERROR);

          return;
        }

      }
    } else {
      try {
        await this.uploadChunk(allChunks?.[0], initialUploadUrl, 0, allChunks?.length);
      } catch (e) {
        this.clearUploadedContent();
        NotificationStore.error(NOTIFICATION_MESSAGES.UPLOAD_ERROR);

        return;
      }
    }

    this.clearUploadedContent();
    this.sendUploadCompletionRequest(uploadId, videoId, activeConversationName, userId);
  };

  @action
  resetState = () => {
    this.paidFeaturePrice = null;
    this.availableFeatures = {
      canSendAudioMessages: false,
      canSendVideos: false,
    };
    this.paidFeaturePassIsAvailable = null;
    this.selectedAnchorName = null;
    this.selectedAnchorId = null;
    this.videoFile = null;
    this.videoSrc = null;
    this.showUploadUi = false;
    this.uploadProgress = 0;
    this.isLoading = false;
    this.userId = null;
  };
}

export default new PaidFeatureStore();

export function memberCombinedOpenChatsList() {
  if (!this.rootStore.loggedInUser.account.anchor) {
    const anchors = this.memberAnchors.filter((a) => {
      const index = this.openChatsList.findIndex((c) => c.user.username === a.username);

      return index === -1;
    }).map((a) => {
      return {
        name: a.username,
        subscribedToAnchor: true,
        user: a,
        active: false,
        lastMessage: 'Start a conversation',
        online: false,
      };
    });

    return [...this.openChatsList, ...anchors];
  }
}

import initials from '../../../../utils/string';


export function activeConversation() {
  return this.activeConversationName ? this.conversations.data.filter((c) => c.name === this.activeConversationName)[0] : null;
}

export function myConversationMemberObject() {
  if (this.activeConversation) {
    return this.activeConversation?.requester;
  }

  return {};
}

export function activeConversationHeader() {
  if (this.activeConversation) {
    const user = this.activeConversation?.partner;

    return {
      username: user.custom && user.nickname ? user.nickname : user.username,
      initials: initials(user.custom && user.custom.nickname ? user.custom.nickname : user.username),
      online: this.usersPresence.get(user.username.toLowerCase()),
      anchorImage: user?.image,
      id: user?.custom?.anchorId || user?.temptationId,
    };
  }

  return null;
}

export function seenTimestamp() {
  if (this.activeConversation) {
    let timestamp = null;
    const partnerTimestamp = this.activeConversation?.partner?.lastReadTimestamp;
    for (let i = this.activeConversationMessages.length - 1; i >= 0; i--) {
      if (this.myConversationMemberObject.username === this.activeConversationMessages[i].username) {
        if (partnerTimestamp >= this.activeConversationMessages[i].timestamp) {
          timestamp = this.activeConversationMessages[i].timestamp;
          break;
        }
      }
    }

    return timestamp;
  }
}

export function isBanned() {
  if (this.activeConversation) {
    return this.activeConversation?.metadata?.banned;
  }
}

export function isConversationActive() {
  const { notAFan, fanCanWriteMessages } = this.activeConversation?.custom || {};
  if (fanCanWriteMessages === true) {
    return true;
  }

  if (notAFan === true && fanCanWriteMessages === false) {
    return true;
  }

  if (!fanCanWriteMessages && !notAFan) {
    return true;
  }

  return false;
}

export function fanCanSendMessages() {
  const { fanCanWriteMessages } = this.activeConversation?.custom || {};

  return fanCanWriteMessages === true;
}


import React from 'react';

function ConfirmationComponent(props) {
  const {
    styles,
    fieldToConfirm,
    dataToConfirm,
    onConfirm,
    onReject,
  } = props;

  return (
    <div className={styles.confirmationContainer}>
      <div className={styles.textContainer}>
        <h2>Confirmation</h2>
        <hr />
        <p>Please check that this is the correct number for your security code before continuing.</p>
        <div className={styles.dataContainer}>
          {dataToConfirm}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <button
          className={styles.cancelButton}
          onClick={onReject}>
          BACK
        </button>
        <button
          className={styles.acceptButton}
          onClick={onConfirm}>
          CONFIRM
        </button>
      </div>
    </div>
  );
}

export default ConfirmationComponent;

import * as services from '../services/PrivateMessagingServices';


export async function createConversation(username) {
  const payload = {};
  if (this.rootStore.loggedInUser.account.anchor) {
    payload.fanName = username;
    payload.anchorName = this.rootStore.loggedInUser.account.username;
  } else {
    payload.fanName = this.rootStore.loggedInUser.account.username;
    payload.anchorName = username;
  }
  const { error, data } = await services.createConversation(payload);
  if (error) {
    if (error.response.status === 409) {
      let response = null;
      if (this.rootStore.loggedInUser.account.anchor) {
        response = await services.getConversation(payload.fanName);
      } else {
        response = await services.getConversation(payload.anchorName);
      }
      if (response.data) {
        if (this.rootStore.loggedInUser.account.anchor) {
          const rawData = response.data.data || [];
          const conversation = rawData.filter((c) => c.members[1].username === username);
          conversation[0].isNewConversation = true;

          // Check if that selected empty conversation is already in the list
          // to avoid having multiple conversations with the same fan
          const conversationExists = this.conversations.data.filter((c) => c.members[1].username === username);
          conversationExists.length === 1 && this.conversations.data.unshift(conversation[0]);

          this.changeActiveTab({ role: 'both', tab: 'open-chats' });
          this.activateConversation({ conversationName: conversation[0].name });
        } else {
          this.activateConversation({ conversationName: response.data.data[0].name });
        }
      }
    }
  } else {
    // Add "isNewConversation" flag to make a clear distionction
    // between the empty conversations that were not selected
    // and the one that was just selected
    data.data.isNewConversation = true;
    this.conversations.data.unshift(data.data);
    if (this.rootStore.loggedInUser.account.anchor) {
      this.changeActiveTab({ role: 'both', tab: 'open-chats' });
    }
    this.activateConversation({ conversationName: data.data.name });
  }
}

import * as services from '../services/PrivateMessagingServices';


const REQ_NUMBER_OF_MESSAGES_BEFORE = 30;

export async function loadPrivateChatData() {
  if (!this.rootStore.loggedInUser.account.anchor) {
    const responses = await Promise.all([
      services.loadStartedConversationsForFans(),
      services.getListOfAnchorsMemberSubscribedTo(this.rootStore.loggedInUser.account.id),
    ]);
    if (responses[0].data && responses[1].data) {
      this.conversations.loading = false;
      this.conversations.data = responses[0].data.data.conversations;
      this.memberAnchors = responses[1].data.data;
    }
  } else {
    const { data } = await services.getStartedConversations();
    if (data) {
      this.conversations.data = data.data;
      this.conversations.loading = false;
    }
  }
}

export async function getFansList() {
  this.fansList.loading = true;
  const { data } = await services.getFansList(this.rootStore.loggedInUser.account.anchor.id);
  this.fansList.loading = false;
  if (data) {
    const rawData = data.data || [];
    this.fansList.data = rawData.filter?.((f) => {
      let shouldInclude = true;
      this.conversations.data.forEach((c) => {
        // In "Subscribers" tab display all empty conversations
        // If there is no lastMessage, keep that fan in this list
        if ((c.members[0].username === f.username || c.members[1].username === f.username) && c.lastMessage) {
          shouldInclude = false;
        }
      });

      return shouldInclude;
    });
  }
}

export async function loadConversationSessionMessages(hasUnreadMessages) {
  const methods = {
    next: this.conversationSession.session.getNextMessages,
    prev: this.conversationSession.session.getPreviousMessages,
  };
  const timestamp = this.myConversationMemberObject.lastReadTimestamp;
  const { messages } = await services.loadMessages(methods, timestamp);
  this.conversationSession.loading = false;
  if (messages) {
    if (messages.length && hasUnreadMessages) {
      this.conversationSession.session.updateLastReadTimestamp(messages[messages.length - 1].timestamp);
    }
    this.activeConversationMessages = messages;
  }
}

export async function loadConversationSessionMessagesAsync() {
  const method = this.conversationSession.session.getAroundMessages;
  let loadedMessages;

  if (this.hasUnreadMessages) {
    const { messages } = await services.loadAroundMessages(method, Date.now());
    loadedMessages = messages;
  } else {
    const { messages } = await services.loadAroundMessages(method, Date.now(), REQ_NUMBER_OF_MESSAGES_BEFORE, 0);
    loadedMessages = messages;
  }

  if (loadedMessages) {
    if (loadedMessages.length && this.hasUnreadMessages) {
      this.conversationSession.session.updateLastReadTimestamp(loadedMessages[loadedMessages.length - 1].timestamp);
    }
    this.activeConversationMessages = loadedMessages;
    this.conversationSession.loading = false;
  }
}

export async function loadOldConversationMessages(messages) {
  const method = this.conversationSession.session.getAroundMessages;
  this.loadingOldMessages = true;


  const { messages: oldMessages } = await services.loadAroundMessages(method, this.activeConversationMessages[0].timestamp, REQ_NUMBER_OF_MESSAGES_BEFORE, 0);
  if (this.activeConversationMessages[0].id === oldMessages[0].id) {
    this.loadingOldMessages = false;
    this.reachedEndOfMessageHistory = true;

    return;
  }

  if (oldMessages.length && this.hasUnreadMessages) {
    this.conversationSession.session.updateLastReadTimestamp(oldMessages[oldMessages.length - 1].timestamp);
  }

  this.activeConversationMessages = [...oldMessages, ...messages];
  this.loadingOldMessages = false;

}

import { action, observable } from 'mobx';

import { ANCHOR_FEATURED_LIST_CLIPSTORE_ITEMS_EWALLET, ANCHOR_VIEW_PURCHASED_CLIPSTORE_GALLERY, ANCHOR_VIEW_PURCHASED_CLIPSTORE_VIDEO, LIST_EPAY_PACKAGES_ITEM } from '../constants/apiUrls/apiUrls';
import callApi from '../utils/callApi';


class PayPerViewStore {
  @observable selectedClipPrice = { price: 0, title: '', id: 0, type: '' };
  @observable isFirstWSEvent = true;

  @action setIsFirstWSEvent = (value) => {
    this.isFirstWSEvent = value;
  };

  @observable intervalID = null;

  @action setIntervalID = (value) => {
    this.intervalID = value;
  };

  @observable payPerViewNav = 'New';

  @action setPayPerViewNav = (value) => {
    this.payPerViewNav = value;
  };

  @observable paymentLoading = false;

  @action setPaymentLoading = (value) => {
    this.paymentLoading = value;
  };

  @observable purchasedClip = { id: '', type: '' };

  @action setPurchasedClip = (value) => {
    this.purchasedClip = value;
  };

  @observable showPayPerViewModal = false;

  @action setShowPayPerViewModal = (value) => {
    this.showPayPerViewModal = value;
  };

  @observable statusMessage = '';

  @action setStatusMessage = (value) => {
    this.statusMessage = value;
  };

  @observable newItemWasPurchased = false;

  @action setNewItemWasPurchased = (value) => {
    this.newItemWasPurchased = value;
  };

  @observable timerStarted = false;

  @action setTimerStarted = (value) => {
    this.timerStarted = value;
  };

  @observable currentlySelectedAnchor = '';

  @action setCurrentlySelectedAnchor = (value) => {
    this.currentlySelectedAnchor = value;
  };

  @observable currentlySelectedAnchorID = '';

  @action setCurrentlySelectedAnchorID = (value) => {
    this.currentlySelectedAnchorID = value;
  };

  @observable shouldRefreshItemsList = false;

  @action setShouldRefreshItemsList = (value) => {
    this.shouldRefreshItemsList = value;
  };

  @observable packages = [];

  @observable contentToViewQueue = [];

  decideContentUrl = (itemId, itemType, temptationId) => {
    if (itemType === 'VIDEO') {
      return ANCHOR_VIEW_PURCHASED_CLIPSTORE_VIDEO.
        replace('{id}', encodeURIComponent(temptationId)).
        replace('{videoId}', encodeURIComponent(itemId));
    }

    return ANCHOR_VIEW_PURCHASED_CLIPSTORE_GALLERY.
      replace('{id}', encodeURIComponent(temptationId)).
      replace('{galleryId}', encodeURIComponent(itemId));
  };

  @action clearContentQueue = () => {
    this.contentToViewQueue = [];
  }

  @action purchaseNotifyEventHandler(event) {
    const { itemId, itemType, temptationId } = event.content;
    if (this.isFirstWSEvent && parseInt(this.purchasedClip.id, 10) !== parseInt(itemId, 10)) {

      this.setIsFirstWSEvent(false);

      return;
    }
    const url = this.decideContentUrl(itemId, itemType, temptationId);
    callApi(url, 'GET', undefined, undefined, undefined, true, true, true).
      then((res) => {
        this.contentToViewQueue = [res?.data?.id || res?.data?.videoId];
        clearTimeout(this.intervalID);
        this.setIntervalID(null);
        this.setTimerStarted(false);
        this.setPaymentLoading(false);
        this.setPayPerViewNav('Purchased');
        this.setNewItemWasPurchased(true);
        if (window.FeaturedSection) {
          window.FeaturedSection.openPurchased();
        }
      }).
      catch((err) => {
        console.error(`There was an error: ${err}`);
      });
  }

  handleFreeContentForFanPlus = (id, price, type, anchorId, callBack) => {
    const url = ANCHOR_FEATURED_LIST_CLIPSTORE_ITEMS_EWALLET.replace('{anchorId}', encodeURIComponent(anchorId));
    const body = JSON.stringify({
      itemId: id,
      price,
      itemType: type,
    });
    this.paymentLoading = true;
    callApi(url, 'post', {}, body, {}, true, true, true).then((res) => {
      if (res.status === 200) {
        this.paymentLoading = false;
        if (typeof callBack === 'function') {
          callBack();
        }
      }
    }).catch((e) => {
      this.paymentLoading = false;
    });
  };

  closePayPerViewModal = () => {
    this.setShowPayPerViewModal(false);
    this.setStatusMessage('');
  };

  startPurchaseEventTimerCountDown = () => {
    this.setTimerStarted(true);
    const timer = setTimeout(() => {

      if (this.timerStarted && this.intervalID) {
        this.setStatusMessage('There was an error. Please try again later.');
        this.setShowPayPerViewModal(true);
        this.setPaymentLoading(false);
      } else {
        this.setTimerStarted(false);
      }
    }, 30000);
    this.setIntervalID(timer);
  };

  @action setClipStorePackages = (user) => {
    if (this.packages.length) {
      return;
    }
    const username = sessionStorage.getItem('username') || (user?.user && user?.user?.username);
    const token = sessionStorage.getItem('token') || (user?.user && user?.user?.token);
    callApi(LIST_EPAY_PACKAGES_ITEM, 'get', { token, userName: username }, {}, {}, false, false, false).
      then((res) => {
        this.packages = res.data.products;
      }).catch();
  };

  @action setSelectedClipPrice = (value) => {
    this.selectedClipPrice = value;
  };

  @action openPayPerViewModal = (price, title, id, type, customPriceForFanPlus, user, anchorId, callBack) => {
    const isAnchor = user && user.user ? user.user.account.anchor : false;
    this.setSelectedClipPrice({ price, title, id, type });

    if (!isAnchor && customPriceForFanPlus === 0 && price === 0) {
      this.handleFreeContentForFanPlus(id, price, type, anchorId, callBack);
    }

    if (!isAnchor && (price !== 0 && title !== '')) {
      this.setShowPayPerViewModal(true);
    }

    this.setPurchasedClip({ id, type: type.toString() });
  };
}

export default new PayPerViewStore();

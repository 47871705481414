import React, { useCallback } from 'react';
import {
  Switch,
  Route,
  BrowserRouter,
  Redirect,
  matchPath,
} from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';

import ProtectedRoute from '../../utils/ProtectedRoute';
import AnchorRoute from '../../utils/AnchorRoute';
import UserContextRoute from '../../utils/UserContextRoute';
import { UserContext } from '../../context';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import OAuthLogin from '../../pages/OAuthLogin/OAuthLogin';
import { PROMO_CODES } from '../../constants/promoCodes';

import RoutesWithSlugPath from './RoutesWithSlugPath';
import { isNakedNewsLiveDomain, isNudeDomain } from '../../utils/helpers';
import withSuspense from '../HOC/WithSuspense';
import FullLoader from '../Loaders/FullLoader';
const PlayboyLifetimeCampaign = React.lazy(() => import('../../pages/LifetimePlayboy/PlayboyLifetimeCampaign'));


const TrendingPage = React.lazy(() => import('../TrendingPage/TrendingPage'));
const TourPage = React.lazy(() => import('../../pages/TourPage'));
const TourPageGifs = React.lazy(() => import('../../pages/TourPageGifs'));
const AffiliateProgram = React.lazy(() => import('../../pages/AffiliateProgram/AffiliateProgram'));
const AffiliateRegistration = React.lazy(() => import('../../pages/AffiliateProgram/Registration/AffiliateRegistration'));
const AffiliateLogin = React.lazy(() => import('../../pages/AffiliateProgram/Login/Login'));
const FreeSegmentsPage = React.lazy(() => import('../../pages/FreeSegments/FreeSegmentsPage'));
const FanZone = React.lazy(() => import('../../pages/FanZone/FanZone'));
const MediaVaultPage = React.lazy(() => import('../../pages/MediaVault/MediaVault'));
const MemberChat = React.lazy(() => import('../../pages/MemberChat/MemberChat'));
const CreateAccountPage = React.lazy(() => import('../../pages/CreateAccount/CreateAccount'));
const AnchorSocialMediaSettings = React.lazy(() =>
  import('../../pages/AnchorDashboard/AnchorSocialMediaSettings/AnchorSocialMediaSettings'));
const PrivateMessaging = React.lazy(() => import('../../pages/AnchorDashboard/PrivateMessaging/PrivateMessaging'));
const AnchorDashboard = React.lazy(() => import('../../pages/AnchorDashboard/AnchorDashboard'));
const AnchorsPage = React.lazy(() => import('../../pages/Anchors/Anchors'));
const NewsPage = React.lazy(() => import('../../pages/News/News'));
const ContactUs = React.lazy(() => import('../../pages/ContactUs/ContactUs'));
const ArchivesPage = React.lazy(() => import('../../pages/Archives/Archives'));
const AuditionsPage = React.lazy(() => import('../../pages/Auditions/Auditions'));
const DiscountsPage = React.lazy(() => import('../../pages/DiscountPage/DiscountsPage'));
const ExternalPartnerDiscountPage = React.lazy(() => import('../../pages/DiscountPage/ExternalPartnerDiscountPage'));
const CheckoutPage = React.lazy(() => import('../../pages/Checkout/Checkout'));
const ErrorPage = React.lazy(() => import('../../pages/ErrorPage/ErrorPage'));
const CookiePolicyPage = React.lazy(() => import('../../pages/CookiesPolicy/CookiesPolicy'));
const LoginWithCAM4 = React.lazy(() => import('../generalComponents/LoginWithCAM4'));
const Cam4OAuthLogin = React.lazy(() => import('../../pages/Cam4OAuth/Cam4OAuthLogin'));
const EmailPrefPage = React.lazy(() => import('../../pages/ManageAccount/EmailPreferences'));
const PrivacyPolicyPage = React.lazy(() => import('../../pages/PrivacyPolicy/PrivacyPolicy'));
const CustomerServicePage = React.lazy(() => import('../../pages/CustomerService/CustomerService'));
const TwoTwoFiveSevenPage = React.lazy(() => import('../../pages/TwoTwoFiveSevenPage'));
const BillingSupportPage = React.lazy(() => import('../../pages/BillingSupport/BillingSupport'));
const FAQPage = React.lazy(() => import('../../pages/FAQ/FAQ'));
const CookieSettingsPage = React.lazy(() => import('../../pages/CookiesSettings/CookiesSettings'));
const TermsAndConditionsPage = React.lazy(() => import('../../pages/TermsAndConditions/TermsAndConditions'));
const HomePage = React.lazy(() => import('../../pages/Home/HomePage'));
const ManageAccountPage = React.lazy(() => import('../../pages/ManageAccount/ManageAccount'));
const MediaPage = React.lazy(() => import('../../pages/Media/Media'));
const ResetPasswordPage = React.lazy(() => import('../../pages/PasswordManagement/ResetPassword'));
const AuthorizeDevicePage = React.lazy(() => import('../../pages/AuthorizeDevice/AuthorizeDevice'));
const SubscriptionsPage = React.lazy(() => import('../../pages/ManageAccount/SubscriptionsPage'));
const LoginPage = React.lazy(() => import('../../pages/Login/Login'));
const EmailLoginPage = React.lazy(() => import('../../pages/Login/EmailLogin'));
const NewPasswordPage = React.lazy(() => import('../../pages/PasswordManagement/NewPassword'));
const FeaturedContent = React.lazy(() => import('../../pages/FeaturedContent/FeaturedContent'));
const CampaignOverlay = React.lazy(() => import('../../pages/CampaignOverlay/CampaignOverlay'));
const AffiliateSFWLander = React.lazy(() => import('../../pages/AffiliateLandingPages/AffiliateSFWLander'));

const Routes = ({
  userPremiumExpired,
  showPremiumUpgradeBar,
  closePremiumUpgradeBar,
  activeCampaigns,
  anchorPortalCampaign,
}) => {
  const user = React.useContext(UserContext);
  const isAnchor = user.user ? user.user.account.anchor : null;
  const footerUnavailable = isAnchor && isMobileOnly;
  const setAtc = () => {
    document.cookie = '_atc=XPOSEDH; expires=0; path=/;';
  };

  const basename = process.env.REACT_APP_MR_SUBFOLDER ? process.env.REACT_APP_MR_SUBFOLDER : '';
  const homePage = isNakedNewsLiveDomain ? '/live' : '/home';

  const HomeRedirect = ({ location }) => {
    const homePage = `/home${location?.search}`

    return <Redirect to={homePage} />;
  };

  const renderCheckoutRoute = useCallback(({ match, location, history }) => (
    <React.Suspense fallback={<FullLoader />}>
      <CreateAccountPage
        anchorPortalCampaign={anchorPortalCampaign}
        history={history}
        isPlayboyPromoPage={true}
        location={location}
        match={match}
        user={user}
      />
    </React.Suspense>
  ), [anchorPortalCampaign, user]);

  return (
    <div id="application">
      <BrowserRouter basename={basename}>
        <>
          <Route
            render={(props) => {
              const isAnchorPortal = matchPath(
                props.location.pathname,
                '/dashboard'
              );
              const isAffiliatePage = matchPath(
                props.location.pathname, '/affiliates'
              )

              const isGuestHomePage = matchPath(
                props.location.pathname, '/home'
              ) && !user?.authenticated

              return (
                <Header
                  activeCampaign={activeCampaigns?.find((c) => c.hasTimer !== false)}
                  closePremiumUpgradeBar={closePremiumUpgradeBar}
                  isAffiliatePage={isAffiliatePage}
                  isAnchorPortal={isAnchorPortal}
                  isGuestHomePage={isGuestHomePage}
                  showPremiumUpgradeBar={showPremiumUpgradeBar}
                  userPremiumExpired={userPremiumExpired}
                  {...props}
                />
              );
            }}
          />
          <Route
            render={(props) => (
              <React.Suspense fallback={null}>
                <CampaignOverlay
                  matchParam="?_atc=ROMIRAINV"
                  purchaseUrl="/account/purchase?_atc=ROMIRAINV"
                  shouldBeAuthenticated={false}
                  user={user}
                  {...props}
                />
              </React.Suspense>
            )}
          />
          <Switch>
            <Route exact={true} path="/dashboard/broadcast">
              <Redirect to="/" />
            </Route>
            <Route
              path="/dashboard"
              render={({ match: { url } }) => (
                <>
                  <AnchorRoute
                    component={withSuspense(AnchorDashboard, <FullLoader />)}
                    exact={true}
                    path={`${url}/`}
                  />
                  <AnchorRoute
                    component={withSuspense(AnchorSocialMediaSettings, <FullLoader />)}
                    exact={true}
                    path={`${url}/social-media-settings`}
                  />
                  <AnchorRoute
                    component={withSuspense(PrivateMessaging, <FullLoader />)}
                    exact={true}
                    path={`${url}/private-chat`}
                  />
                </>
              )}
            />

            <Route
              path="/chat"
              render={() => {
                const isWhitelisted = user ? user?.features.includes('ANCHOR_PORTAL') : false;

                return isWhitelisted ? (
                  <ProtectedRoute
                    component={withSuspense(MemberChat, <FullLoader />)}
                    exact={true}
                    path="/chat"
                    user={user}
                  />
                ) : (
                  <Redirect to="/" />
                );
              }}
            />
            <Route exact={true} path="/" render={(props) => <HomeRedirect {...props} />} />
            <UserContextRoute
              allActiveCampaigns={activeCampaigns}
              component={withSuspense(HomePage, <FullLoader />)}
              exact={true}
              path="/home"
              user={user}
            />
            <UserContextRoute
              component={withSuspense(Cam4OAuthLogin, <FullLoader />)}
              exact={true}
              path="/cam4oauth"
            />
            <Route
              exact={true}
              path="/cam4/login"
              render={() => (
                <React.Suspense fallback={<FullLoader />}>
                  <LoginWithCAM4 shouldDoLogin={true} user={user} />
                </React.Suspense>
              )}
            />
            <Route exact={true} path="/blog">
              <Redirect to="/news" />
            </Route>
            <Route exact={true} path="/blog/category/:id">
              <Redirect to="/news/category/:id" />
            </Route>

            <Route exact={true} path="/blog/tag/:tag">
              <Redirect to="/news/tag/:tag" />
            </Route>
            <UserContextRoute
              component={withSuspense(MediaVaultPage, <FullLoader />)}
              exact={true}
              path="/clip-store"
            />
            <UserContextRoute
              component={withSuspense(MediaVaultPage, <FullLoader />)}
              exact={true}
              path="/purchased-items"
            />
            <UserContextRoute
              anchorsToShow={['current-anchors', 'guest-anchors', 'past-anchors']}
              component={withSuspense(AnchorsPage, <FullLoader />)}
              exact={true}
              path="/naked-news-anchors"
            />
            <UserContextRoute
              component={withSuspense(AuditionsPage, <FullLoader />)}
              exact={true}
              path="/auditions"
            />
            <UserContextRoute component={withSuspense(NewsPage, <FullLoader />)} exact={true} path="/news" />
            <UserContextRoute
              component={withSuspense(NewsPage, <FullLoader />)}
              exact={true}
              path="/news/category/:id"
            />
            <UserContextRoute
              component={withSuspense(NewsPage, <FullLoader />)}
              exact={true}
              path="/news/tag/:tag"
            />

            <Route
              component={withSuspense(CookiePolicyPage, <FullLoader />)}
              exact={true}
              path="/cookie-policy"
              strict={true}
            />
            <Route
              component={withSuspense(PrivacyPolicyPage, <FullLoader />)}
              exact={true}
              path="/privacy_policy"
              strict={true}
            />
            <Route
              component={withSuspense(CustomerServicePage, <FullLoader />)}
              exact={true}
              path="/privacy_policy/3rd-parties"
              strict={true}
            />
            <Route component={withSuspense(FAQPage, <FullLoader />)} exact={true} path="/faq" strict={true} />
            <Route
              component={withSuspense(CookieSettingsPage, <FullLoader />)}
              exact={true}
              path="/cookie-settings"
              strict={true}
            />
            <Route
              component={withSuspense(TermsAndConditionsPage, <FullLoader />)}
              exact={true}
              path="/terms_of_service"
              strict={true}
            />
            <Route
              component={withSuspense(TwoTwoFiveSevenPage, <FullLoader />)}
              exact={true}
              path="/2257"
              strict={true}
            />
            <Route
              component={withSuspense(BillingSupportPage, <FullLoader />)}
              exact={true}
              path="/billing/processing"
              strict={true}
            />
            <Route
              component={withSuspense(MediaPage, <FullLoader />)}
              exact={true}
              path="/media"
              strict={true}
            />
            <Route
              component={withSuspense(ContactUs, <FullLoader />)}
              exact={true}
              path="/contact"
              strict={true}
            />
            <Route
              component={withSuspense(ContactUs, <FullLoader />)}
              exact={true}
              path="/contact/complaint"
              strict={true}
            />
            <ProtectedRoute
              component={withSuspense(ManageAccountPage, <FullLoader />)}
              exact={true}
              path="/account"
            />
            <UserContextRoute component={withSuspense(FanZone, <FullLoader />)} exact={true} path="/fan-zone" />
            <Route
              component={withSuspense(TrendingPage, <FullLoader />)}
              exact={true}
              path="/trending"
              strict={true}
            />
            <Redirect
              render={() => <Redirect to="/" />}
              exact={true}
              path="/summerpack-purchase"
            />
            <Route component={OAuthLogin} exact={true} path="/oauth2/login" />

            <UserContextRoute
              component={withSuspense(ArchivesPage, <FullLoader />)}
              exact={true}
              path="/archives"
            />
            <UserContextRoute
              component={withSuspense(ArchivesPage, <FullLoader />)}
              exact={true}
              path="/archives/tag/:tag"
            />
            <ProtectedRoute
              component={withSuspense(ExternalPartnerDiscountPage, <FullLoader />)}
              exact={true}
              path="/member-specials"
            />
            <Route
              exact={true}
              path="/portal"
              render={() => {
                if (user.user && user.user.account.anchor) {
                  return <Redirect to={{ pathname: '/dashboard' }} />;
                }

                return (
                  <React.Suspense fallback={<FullLoader />}>
                    <ErrorPage />
                  </React.Suspense>
                );
              }}
            />
            <UserContextRoute
              component={withSuspense(CheckoutPage, <FullLoader />)}
              exact={true}
              path="/checkout"
            />
            <Route
              component={withSuspense(ResetPasswordPage, <FullLoader />)}
              exact={true}
              path="/account/password/new"
            />
            <Route
              component={withSuspense(NewPasswordPage, <FullLoader />)}
              path="/account/password/reset/:token"
            />
            <Route component={withSuspense(LoginPage, <FullLoader />)} exact={true} path="/account/login" />
            <Route component={withSuspense(EmailLoginPage, <FullLoader />)} exact={true} path="/account/email-login" />
            <Route
              component={withSuspense(AuthorizeDevicePage, <FullLoader />)}
              exact={true}
              path="/account/devices"
            />
            <ProtectedRoute
              component={withSuspense(SubscriptionsPage, <FullLoader />)}
              exact={true}
              path="/account/subscriptions"
            />
            <ProtectedRoute
              component={withSuspense(EmailPrefPage, <FullLoader />)}
              exact={true}
              path="/account/email_preferences"
            />
            <Route
              component={withSuspense(TourPageGifs, <FullLoader />)}
              exact={true}
              path="/tour/gifs"
            />
            <Route
              exact={true}
              path="/tour"
              render={(props) => {
                if (isNudeDomain && !user.authenticated) {
                  return withSuspense(TourPage, <FullLoader />)(props);
                }

                return <Redirect to="/" />;
              }}
            />
            <Route
              path="/hollywood"
              render={() => {
                setAtc();

                return <Redirect to={'/archives?segmentid=219'} />;
              }}
            />
            <UserContextRoute
              component={withSuspense(AffiliateProgram, <FullLoader />)}
              exact={true}
              path="/affiliates"
            />
            <Route
              component={withSuspense(AffiliateRegistration, <FullLoader />)}
              exact={true}
              path="/affiliates/signup"
            />
            <Route
              component={withSuspense(AffiliateLogin, <FullLoader />)}
              exact={true}
              path="/affiliates/login"
            />
            <Route
              component={withSuspense(AffiliateLogin, <FullLoader />)}
              exact={true}
              path="/affiliates/password/reset"
            />
            <Route
              component={withSuspense(AffiliateLogin, <FullLoader />)}
              exact={true}
              path="/affiliates/password/set"
            />
            <Route
              exact={true}
              path="/account/signup"
              render={({ match, location, history }) => (
                <React.Suspense fallback={<FullLoader />}>
                  <ManageAccountPage
                    history={history}
                    isNewUserPasswordSetupPage={true}
                    location={location}
                    match={match}
                    user={user}
                  />
                </React.Suspense>
              )}
            />
            <Route
              exact={true}
              path="/cherie-deville"
              component={withSuspense(AffiliateSFWLander, <FullLoader />)}
            />
            <Route
              exact={true}
              path="/texas-patti"
              component={withSuspense(AffiliateSFWLander, <FullLoader />)}
            />
            <Route
              exact={true}
              path="/naked-amateur-auditions/:slug*"
              render={({ match, location }) => (
                <React.Suspense fallback={<FullLoader />}>
                  <AuditionsPage
                    location={location}
                    slug={match.params.slug}
                    user={user}
                  />
                </React.Suspense>
              )}
            />
            <Route
              component={withSuspense(FeaturedContent, <FullLoader />)}
              exact={true}
              path="/featured-content"
            />
            <UserContextRoute
              component={withSuspense(FreeSegmentsPage, <FullLoader />)}
              exact={true}
              path="/free-segments"
            />
            <Route
              exact={true}
              path="/account/purchase"
              render={({ match, location, history }) => (
                <React.Suspense fallback={<FullLoader />}>
                  <CreateAccountPage
                    anchorPortalCampaign={anchorPortalCampaign}
                    history={history}
                    isAccountCreationPage={true}
                    listOfActiveCampaigns={activeCampaigns.filter((c) => c.affiliateCode !== PROMO_CODES.TRIALFANCLUBS) || []}
                    location={location}
                    match={match}
                    user={user}
                  />
                </React.Suspense>
              )}
            />
            <Route
              exact={true}
              path="/lifetime-promo-live"
              render={({ match, location, history }) => (
                <React.Suspense fallback={<FullLoader />}>
                  <CreateAccountPage
                    anchorPortalCampaign={anchorPortalCampaign}
                    history={history}
                    isMrSkinPromoPage={true}
                    location={location}
                    match={match}
                    user={user}
                  />
                </React.Suspense>
              )}
            />
            <Route
              exact={true}
              path="/lifetime-promo-playboy"
              render={({ match, location, history }) => (
                <React.Suspense fallback={<FullLoader />}>
                  <PlayboyLifetimeCampaign
                    anchorPortalCampaign={anchorPortalCampaign}
                    history={history}
                    isPlayboyPromoPage={true}
                    location={location}
                    match={match}
                    user={user}
                  />
                </React.Suspense>
              )}
            />
            <Route
              exact={true}
              path="/lifetime-promo"
              render={renderCheckoutRoute}
            />
            <Route
              exact={true}
              path="/nnl-promo-card"
              render={({ match, location, history }) => (
                <React.Suspense fallback={<FullLoader />}>
                  <CreateAccountPage
                    anchorPortalCampaign={anchorPortalCampaign}
                    history={history}
                    location={location}
                    match={match}
                    user={user}
                  />
                </React.Suspense>
              )}
            />
            <Route
              exact={true}
              path="/nn-promo-card"
              render={({ match, location, history }) => (
                <React.Suspense fallback={<FullLoader />}>
                  <CreateAccountPage
                    anchorPortalCampaign={anchorPortalCampaign}
                    history={history}
                    location={location}
                    match={match}
                    user={user}
                  />
                </React.Suspense>
              )}
            />
            <Route
              exact={true}
              path="/promo/asn-awards"
              render={({ match, location, history }) => (
                <React.Suspense fallback={<FullLoader />}>
                  <CreateAccountPage
                    anchorPortalCampaign={anchorPortalCampaign}
                    history={history}
                    location={location}
                    match={match}
                    user={user}
                  />
                </React.Suspense>
              )}
            />
            <Route
              exact={true}
              path="/2023-calendar-promo"
              render={({ match, location, history }) => (
                <React.Suspense fallback={<FullLoader />}>
                  <DiscountsPage
                    activeCampaign={activeCampaigns?.find((c) => c.affiliateCode === PROMO_CODES.TRIALFANCLUBS) || {}}
                    anchorPortalCampaign={anchorPortalCampaign}
                    className="calendar-promo-2023"
                    history={history}
                    isChristmasPromo={false}
                    isCyberMondayPromo={false}
                    location={location}
                    match={match}
                    user={user}
                  />
                </React.Suspense>
              )}
            />
            <Route
              exact={true}
              path="/christmas-fanzone-2023"
              render={({ match, location, history }) => (
                <React.Suspense fallback={<FullLoader />}>
                  <DiscountsPage
                    activeCampaign={activeCampaigns?.find((c) => c.affiliateCode === PROMO_CODES.TRIALFANCLUBS) || {}}
                    anchorPortalCampaign={anchorPortalCampaign}
                    className="calendar-promo-2023"
                    history={history}
                    isChristmasPromo={true}
                    isCyberMondayPromo={false}
                    location={location}
                    match={match}
                    user={user}
                  />
                </React.Suspense>
              )}
            />
            <Route
              exact={true}
              path="/2025-calendar-promo"
              render={({ match, location, history }) => (
                <React.Suspense fallback={<FullLoader />}>
                  <DiscountsPage
                    activeCampaign={activeCampaigns?.find((c) => c.affiliateCode === PROMO_CODES.TRIALFANCLUBS2025) || {}}
                    anchorPortalCampaign={anchorPortalCampaign}
                    className="calendar-promo-2024"
                    history={history}
                    isChristmasPromo={false}
                    isCyberMondayPromo={false}
                    location={location}
                    match={match}
                    user={user}
                  />
                </React.Suspense>
              )}
            />
            <Route
              exact={true}
              path="/2022-cyber-monday-promo"
              render={({ match, location, history }) => (
                <React.Suspense fallback={<FullLoader />}>
                  <DiscountsPage
                    activeCampaign={activeCampaigns?.find((c) => c.affiliateCode === PROMO_CODES.CYBERMONDAY2022) || {}}
                    anchorPortalCampaign={anchorPortalCampaign}
                    className="cyber-monday-2022"
                    history={history}
                    isChristmasPromo={false}
                    isCyberMondayPromo={true}
                    location={location}
                    match={match}
                    user={user}
                  />
                </React.Suspense>
              )}
            />
            <Route component={withSuspense(ErrorPage, <FullLoader />)} exact={true} path="/not-found" />
            <RoutesWithSlugPath
              activeCampaign={activeCampaigns?.[0] || {}}
              anchorPortalCampaign={anchorPortalCampaign}
            />

          </Switch>
          {!footerUnavailable && (
            <Route render={(props) => <Footer {...props} />} />
          )}
        </>
      </BrowserRouter>
    </div>
  );
};

export default Routes;

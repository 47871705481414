import { isMobileOnly } from 'react-device-detect';


export function displaySidebar() {
  return isMobileOnly ? this.sidebarMobile : true;
}

export function tabsToRender() {
  const tabs = {};
  tabs.active = this.sidebarTabs.active;
  if (!this.rootStore.loggedInUser.account.anchor) {
    tabs.tabs = this.sidebarTabs.tabs.filter((t) => t.role === 'both' || t.role === 'fan');
  } else {
    tabs.tabs = this.sidebarTabs.tabs.filter((t) => t.role === 'both' || t.role === 'anchor');
  }

  return tabs;
}

export function fansUsernames() {
  let filteredList = this.fansList.data || [];
  if (this.searchQuery) {
    filteredList = this.fansList.data.filter((f) => new RegExp(this.searchQuery, 'gi').test(f.username));
  }

  return filteredList.map((f) => {
    return {
      user: f,
      online: this.usersPresence.get(f.username.toLowerCase()),
    };
  });
}

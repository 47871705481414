
import React from 'react';

import InternalHeaderLinks from '../headerComponents/InternalHeaderLinks/InternalHeaderLinks';


const HeaderNav = ({ user, isAnchor }) => (
  <div className="navbar navbar-topnav subnav navbar-header">
    <ul className="nav navbar-nav">
      <InternalHeaderLinks
        isAnchor={isAnchor}
        isLoggedIn={user.authenticated}
        user={user}
      />
    </ul>
  </div>
);

export default HeaderNav;

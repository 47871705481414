import React from 'react';
import { observer } from 'mobx-react';
import { RootStoreContext } from '../../context';

const withUnreadConversationsIndicator = Component => {
  @observer
  class withUnreadConversationsIndicator extends React.Component {

    static contextType = RootStoreContext;

    componentDidMount () {
      this.context.uiStore.shouldGetNumOfConversationsWithUnreadMessages = true;
      this.context.uiStore.getNumOfConversationsWithUnreadMessages();
    }

    render () {
      const num = this.context.uiStore.numOfConversationsWithUnreadMessages;
      return (
        <Component {...this.props} numOfUnreadConversations={num} />
      );
    }
  }
  return withUnreadConversationsIndicator;
}

export default withUnreadConversationsIndicator;
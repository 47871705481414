import { action, observable } from 'mobx';
import {
  createEmailMagicLink,
  verifyEmailMagicLink,
  createSmsLogin,
  verifySmsLogin,
} from '../services/LoginModalServices';
import { storeToken } from '../utils/auth';


class LoginModalStore {
  @observable shouldShowModal = false;

  @observable showFastPassModal = false;

  @observable loading = false;

  @observable ssoSession = null;

  @observable errorMessage = null;

  @action setErrorMessage(message) {
    this.errorMessage = message;
  }

  @action setSsoSession(ssoSession) {
    this.ssoSession = ssoSession;
  }

  @action setLoading(loading) {
    this.loading = loading;
  }

  @action setShowFastPassModal = (value) => {
    this.showFastPassModal = value;
  }

  @action setShouldShowModal = value => {
    this.shouldShowModal = value;
  }

  @action createEmailMagicLink = async (email) => {
    this.setLoading(true);
    const payload = {
      identity: email,
      identityType: 'EMAIL',
    }
    const { error, data } = await createEmailMagicLink(payload);

    this.setLoading(false);
    if (!error) {
      return { success: true };
    }

    return { success: false };
  }

  getRedirectPath = (user) => {
    if (user?.anchor) {
      return '/dashboard';
    }

    return '/';
  }

  getRedirectUri = (redirectPath, data) => {
    const redirectUrlWithoutParams = `${window.location.origin}${redirectPath}`;
    const formattedUrl = new URL(`${data?.data?.redirectUri}`);
    const searchString = new URLSearchParams(formattedUrl.search);
    searchString.append('redirect_uri', redirectUrlWithoutParams);
    formattedUrl.search = searchString.toString();

    return formattedUrl;
  }

  @action verifyEmailMagicLink = async ({ code, email }) => {
    const { error, data } = await verifyEmailMagicLink({ code, email });

    if (!error) {
      this.setErrorMessage(null);
      storeToken({ token: data.data.token, username: data.data.user.username });
      const redirectPath = this.getRedirectPath(data?.data?.user);

      const redirectUri = data?.data?.redirectUri ? this.getRedirectUri(redirectPath, data) : redirectPath;
      window.location.href = redirectUri;
    } else {
      this.setErrorMessage('Something went wrong, please try again.');
    }
  }

  @action createSmsLogin = async (phoneNumber) => {
    this.setLoading(true);

    const payload = { phoneNumber };
    const { error, data } = await createSmsLogin(payload);

    if (!error) {
      this.setSsoSession(data.data.ssoSession);
    }
  }

  @action verifySmsLogin = async (otpCode) => {
    this.setLoading(true);
    const payload = { otpCode };
    const { error, data } = await verifySmsLogin(payload, this.ssoSession);

    if (!error) {
      const redirectUri = `${data.data.redirectUri}?redirect_uri=${process.env.REACT_APP_NAKEDNEWS_UI}`;
      window.location.href = redirectUri;

      return data;
    }

    return { error: 'Invalid code. Please try again' };
  }

}

export default new LoginModalStore();

const getCachedDataIfExists = (key) => {
  const cachedData = localStorage.getItem(key);
  if (cachedData) {
    return JSON.parse(cachedData);
  }
};

export const withCacheStorage = (promisesListWithCacheKey) => {
  const dataArray = [];
  const now = new Date();
  const promiseList = promisesListWithCacheKey.map((promise, index) => {
    const cachedData = getCachedDataIfExists(promise?.cacheKey);
    if (cachedData && (cachedData.expiryTime > now.getTime())) {
      const data = cachedData;
      delete data.expiryTime;
      dataArray[index] = data;

      return Promise.resolve(cachedData);
    }
    if (cachedData && (cachedData.expiryTime < now.getTime())) {
      localStorage.removeItem(promise.cacheKey);
    }

    return promise.fn().then((data) => {
      const dataToStore = { data: { data: data.data.data || data.data } };
      if (!promise?.noCache) {
        dataToStore.expiryTime = now.getTime() + promise.expiryTime;
        localStorage.setItem(promise.cacheKey, JSON.stringify(dataToStore));
      }
      dataArray[index] = data;

      return data;
    });

  });

  // Wait for all promises to resolve
  return Promise.all(promiseList).then(() => dataArray);
};

export const clearCache = (key) => {
  localStorage.removeItem(key);
};

export const EXPIRY_TIMES_IN_MS = {
  '15_MINS': 600000,
  '30_MINS': 1800000,
  '1_HOUR': 3600000,
  '2_HOUR': 7200000,
};

import axios from 'axios';

import forResponse from '../utils/forResponse';
import {
  TULUM_REALITY_SHOW_CHAT_ACCESS,
  TULUM_REALITY_SHOW_STREAMS,
  ANCHOR_SERVICES_URL,
  ANCHOR_ACTIVE_STREAM_TEM,
  ACTIVE_REALITY_SHOWS,
} from '../constants/apiUrls/apiUrls';

import http from './http/index';


export const getChatAccess = () => forResponse(http.post(TULUM_REALITY_SHOW_CHAT_ACCESS));

export const getTulumStreams = () => forResponse(http.get(TULUM_REALITY_SHOW_STREAMS));

export const getAnchorPortalServices = (username) => {
  const url = encodeURI(ANCHOR_SERVICES_URL.replace('{anchorName}', username));

  return forResponse(http.get(url));
};

export const getActiveStream = (streamServiceUrl, username, streamServiceToken) => {
  const url = ANCHOR_ACTIVE_STREAM_TEM.
    replace('{streamServiceUrl}', streamServiceUrl).
    replace('{username}', encodeURIComponent(username));

  return forResponse(axios.get(
    url,
    { headers: { 'Authorization': `Bearer ${streamServiceToken}` } }
  ));
};

export const getActiveRealityShow = () => forResponse(http.get(ACTIVE_REALITY_SHOWS));

import React, { Suspense } from 'react';


const withSuspense = (Component, fallback) => (props) => (
  <Suspense fallback={fallback}>
    <Component {...props} />
  </Suspense>
);

export default withSuspense;

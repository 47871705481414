import http from './http/index';
import forResponse from '../utils/forResponse';
import {
  CREATE_EMAIL_MAGIC_LINK,
  VERIFY_EMAIL_MAGIC_LINK,
  CREATE_SMS_LOGIN,
  VERIFY_SMS_LOGIN,
} from '../constants/apiUrls/apiUrls';

export const createEmailMagicLink = payload => forResponse(http.post(CREATE_EMAIL_MAGIC_LINK, payload));

export const verifyEmailMagicLink = payload => forResponse(http.post(VERIFY_EMAIL_MAGIC_LINK, payload));

export const createSmsLogin = payload => forResponse(http.post(CREATE_SMS_LOGIN, payload));

export const verifySmsLogin = (payload, token) => {
  const url = encodeURI(VERIFY_SMS_LOGIN.replace('{token}', token));
  return forResponse(http.post(url, payload));
};

import { action, observable, runInAction, computed } from 'mobx';
import * as GroupShowServices from '../services/GroupShowServices';
import { ANCHOR_SERVICES } from '../constants/anchorServices';
import forResponse from '../utils/forResponse';
import http from '../services/http';
import { GET_ANCHOR_ID_BY_USERNAME } from '../constants/apiUrls/apiUrls.js';
import LiveBroadcastStore from './LiveBroadcastStore';

const IN_GROUP_SHOW = 'IN_GROUP_SHOW';

class GroupShowStore {
  @observable transitionToGroupShow = false;
  @observable isInGroupShow = false;
  @observable anchorServices = null;
  @observable guestUser = null;
  @observable streamData = null;
  @observable username = null;
  @observable videoRef = null;
  @observable streamMuted = true;
  @observable participantStreamMuted = true;
  @observable groupShowParticipant = {
    videoRef: null,
    anchorServices: null,
    participantUsername: null,
    participantStreamData: null,
    anchorDetails: { name: null, nickname: null, image: null, id: null },
    guestUser: null,
    isFromTempted: false,
    username: null,
  }

  @computed get publicChatService() {
    return this.anchorServices ? this.anchorServices.filter(service => service.name === ANCHOR_SERVICES.CHAT) : [];
  }

  @computed get streamService() {
    return this.anchorServices ? this.anchorServices.filter(service => service.name === ANCHOR_SERVICES.STREAM) : [];
  }

  @computed get gsParticipantStreamService() {
    return this.groupShowParticipant.anchorServices ? this.groupShowParticipant.anchorServices.filter(service => service.name === ANCHOR_SERVICES.STREAM) : [];
  }

  @computed get streamingSource() {
    return this.streamData ? this.streamData?.streamingSourceType : 'TEMPTED';
  }

  @action receivedGroupShowEvent(event) {
    if (!event) {
      return;
    }

    Object.keys(event).forEach(key => {
      if (key.includes('stream_online_')) {
        const temptationId = Number(key.replace('stream_online_', ''));
        const temptationDetails = event.performers.find(performer => performer.temptationId === temptationId);

        if (temptationDetails) {
          if (event[key] === false) {
            setTimeout(() => {
              this.removeGroupShowParticipant(temptationDetails.username);
            }, 2000);
          } else {
            this.groupShowParticipantJoined(temptationDetails.username);
          }
        }
      }
    })
  }

  @action groupShowParticipantJoined(username) {
    const {
      participantStreamData,
      participantUsername
    } = this.groupShowParticipant;

    if (this.username !== username && !participantStreamData) {
      this.rejoinGroupShowParticipant(username);
    }

    if (participantUsername !== username && !this.streamData) {
      this.rejoinGroupShowHost(username);
    }
  }

  @action async getAnchorServices(username) {
    this.username = username;
    const responseAPservices = await GroupShowServices.getAnchorPortalServicesV2(username);

    runInAction(async () => {
      if (responseAPservices.data && responseAPservices.data.data.profileStatus === IN_GROUP_SHOW) {
        // Group show host data
        this.anchorServices = responseAPservices.data.data.services;
        this.guestUser = responseAPservices.data.data.username;
        this.transitionToGroupShow = false;
        this.isInGroupShow = responseAPservices.data.data.profileStatus === IN_GROUP_SHOW;

        // Group show participant data
        this.groupShowParticipant.isFromTempted = !this.publicChatService[0].custom.isSecondaryNNAnchor;
        const secondaryUsername = this.publicChatService[0].custom.secondaryBroadcaster;
        const responseAnchorSummary = await GroupShowServices.getAnchorSummary(secondaryUsername);
        if (responseAnchorSummary.error) {
          this.getAnchorServices(username);
          return;
        } else {
          const { nickname, name, image, id } = responseAnchorSummary.data.data;
          this.groupShowParticipant.anchorDetails = {
            nickname, name, image, id
          };
        }

        this.getGSParticipantAnchorServices(secondaryUsername);
        this.getAnchorActiveStream(username);
      }
    });
  }

  @action async getGSParticipantAnchorServices(username) {
    this.groupShowParticipant.participantUsername = username;

    const { error, data } = await GroupShowServices.getAnchorPortalServicesV2(username);

    runInAction(() => {
      if (!error) {
        this.groupShowParticipant.anchorServices = data.data.services;
        this.groupShowParticipant.guestUser = data.data.username;

        this.getGSParticipantActiveStream(username);
      }
    });
  }

  @action async rejoinGroupShowHost(username) {
    this.username = username;
    const responseAPservices = await GroupShowServices.getAnchorPortalServicesV2(username);

    runInAction(() => {
      if (!responseAPservices.error) {
        this.anchorServices = responseAPservices.data.data.services;
        this.guestUser = responseAPservices.data.data.username;

        this.getAnchorActiveStream(username);
      }
    });
  }

  @action async rejoinGroupShowParticipant(username) {
    const responseAnchorSummary = await GroupShowServices.getAnchorSummary(username);
    runInAction(() => {
      if (responseAnchorSummary.error) {
        this.rejoinGroupShowParticipant(username);
        return;
      } else {
        const { nickname, name, image, id } = responseAnchorSummary.data.data;
        this.groupShowParticipant.anchorDetails = {
          nickname, name, image, id
        }
        this.groupShowParticipant.isFromTempted = !this.publicChatService[0].custom.isSecondaryNNAnchor;
        this.getGSParticipantAnchorServices(username);
      }
    });
  }

  @action async getAnchorActiveStream(username) {
    if (this.streamService.length > 0) {
      const streamServiceUrl = this.streamService[0].url;
      const streamServiceToken = this.streamService[0].token;
      const { error, data } = await GroupShowServices.getAnchorActiveStream(streamServiceUrl, username, streamServiceToken);
      LiveBroadcastStore.setStreamStarted(true);
      runInAction(() => {
        if (!error) {
          this.streamData = data.data[0];
        }
      });
    }
  }

  @action async getGSParticipantActiveStream(username) {
    if (this.gsParticipantStreamService.length > 0) {
      const streamServiceUrl = this.gsParticipantStreamService[0].url;
      const streamServiceToken = this.gsParticipantStreamService[0].token;
      const { error, data } = await GroupShowServices.getAnchorActiveStream(streamServiceUrl, username, streamServiceToken);

      runInAction(() => {
        if (!error) {
          this.groupShowParticipant.participantStreamData = data.data[0];
          this.isInGroupShow = true;
        }
      });
    }
  }

  @action removeGroupShowParticipant(username) {
    if (this.username === username && this.streamData) {
      this.guestUser = null;
      this.streamData = null;
      this.videoRef = null;
      this.streamMuted = true;
    }

    if (this.groupShowParticipant.participantUsername === username && this.groupShowParticipant.participantStreamData) {
      this.groupShowParticipant.videoRef = null;
      this.groupShowParticipant.participantStreamData = null;
      this.groupShowParticipant.guestUser = null;
      this.groupShowParticipant.isFromTempted = false;
      this.participantStreamMuted = true;
    }
  }

  @action resetRemainingGroupShowData() {
    this.anchorServices = null;
    this.username = null;
    this.groupShowParticipant.anchorServices = null;
    this.groupShowParticipant.participantUsername = null;
  }

  @action handleMainStreamMuteToggle = () => {
    if (this.videoRef && this.videoRef.current) {
      const currentMutedState = this.streamMuted;
      this.videoRef.current.muted = !currentMutedState;
      this.streamMuted = !currentMutedState;
    }
  }

  @action handleParticipantStreamMuteToggle = () => {
    if (this.groupShowParticipant.participantStreamData && this.groupShowParticipant.videoRef) {
      const currentMutedState = this.participantStreamMuted;
      this.groupShowParticipant.videoRef.current.muted = !currentMutedState;
      this.participantStreamMuted = !currentMutedState;
    }
  }

  @action toggleStreamVolume(isParticipantStream) {
    if (!isParticipantStream) {
      this.handleMainStreamMuteToggle();
    } else {
      this.handleParticipantStreamMuteToggle();
    }
  }

  @action setInGroupShow = value => {
    this.isInGroupShow = value;
  }

  @action moveToGroupShow = () => {
    this.transitionToGroupShow = true;

    setTimeout(() => {
      window.location.reload();
    }, 15000);
  }

  @action resetStreamVolume = () => {
    this.streamMuted = true;
  }

  @action setHostVideoRef = ref => {
    this.videoRef = ref;
  }

  @action setParticipantVideoRef = ref => {
    this.groupShowParticipant.videoRef = ref;
  }

  getAnchorByUsername = async (username) => {
    const url = GET_ANCHOR_ID_BY_USERNAME.replace('{username}', username);
    return forResponse(http.get(url));
  }
}

export default new GroupShowStore();

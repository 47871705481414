import { SSO_REDIRECT_SESSION, SSO_SESSION_INFO } from "../../constants/apiUrls/apiUrls";
import forResponse from "../../utils/forResponse";
import http from '../http';


export const getSSORedirectUrl = () => forResponse(http.get(SSO_REDIRECT_SESSION));

export const getSSOSessionInfo = (sso_session) =>
  forResponse(http.get(SSO_SESSION_INFO, { params: { sso_session } }));


import React, { useEffect, useState } from 'react';
import './FlipComponent.css';


const FlipComponent = ({ value }) => {

  const [flipped, setFlipped] = useState(false);
  const [valueState, setValueState] = useState(value);
  const [previusValueState, setPreviousValueState] = useState(value);

  useEffect(() => {
    setFlipped(true);
    setValueState((oldValueStat) => {
      setPreviousValueState(oldValueStat);

      return value;
    });
    const timeout = setTimeout(() => {
      setFlipped(false);
    }, 500);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <div className="flipdown flipdown__theme-light">
      <div className="rotor-group">
        <div className={'rotor'}>
          <div className={`rotor-leaf${flipped ? ' flipped' : ''}`}>
            <figure className="rotor-leaf-rear">{valueState}</figure>
            <figure className="rotor-leaf-front">{flipped ? previusValueState : valueState}</figure>
          </div>
          <div className="rotor-top">{valueState}</div>
          <div className="rotor-bottom">{flipped ? previusValueState : valueState}</div>
        </div>
      </div>
    </div>

  );
};

export default FlipComponent;

import axios from 'axios';

import forResponse from '../utils/forResponse';
import { ANCHOR_SERVICES_URL, ANCHOR_SUMMARY_TEM, ANCHOR_ACTIVE_STREAM_TEM } from '../constants/apiUrls/apiUrls';

import http from './http';


export const getAnchorPortalServicesV2 = (username) => {
  const url = ANCHOR_SERVICES_URL.replace('{anchorName}', encodeURIComponent(username));

  return forResponse(http.get(url));
};

export const getAnchorSummary = (username) => {
  const url = ANCHOR_SUMMARY_TEM.replace('{username}', encodeURIComponent(username));

  return forResponse(http.get(url));
};

export const getAnchorActiveStream = (streamServiceUrl, username, streamServiceToken) => {
  const url = ANCHOR_ACTIVE_STREAM_TEM.
    replace('{streamServiceUrl}', streamServiceUrl).
    replace('{username}', encodeURIComponent(username));

  return forResponse(axios.get(
    url,
    { headers: { 'Authorization': `Bearer ${streamServiceToken}` } }
  ));
};

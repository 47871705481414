/**
 * @param promise
 *
 * Purpose of this function is to avoid putting await calls inside try/catch every time
 *
 * Function attaches promise handlers and construct object containing
 * api call response information.
 * If api call succeeds, returned object will only contain data property
 * If it fails, it will only contain error property
 *
 * Example call:
 *
 * const { error, data } = await fetchResource();
 * if (!error) {
 *  handle data
 * } else {
 *  handle error
 * }
 *
 *
 * @returns Promise
 * */
export default (promise) => promise.
  then((data) => {
    return { data };
  }).
  catch((error) => {
    return { error };
  });

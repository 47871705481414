import axios from 'axios';


function callApi(url, method = 'GET', queryParams, postParams, headers, withCredentials = true, withToken = true, withAuthorizationHeader = false) {
  const _o = { headers: { 'content-type': 'application/json; charset=utf-8' } };

  if (headers) {
    for (const k in headers) {
      if (headers.hasOwnProperty(k)) {
        _o.headers[k] = headers[k];
      }
    }
  }

  if (withToken) {
    if (sessionStorage.getItem('username')) {
      _o.headers.username = sessionStorage.getItem('username');
    }

    if (sessionStorage.getItem('token')) {
      if (withAuthorizationHeader) {
        _o.headers.Authorization = `Bearer ${sessionStorage.getItem('token')}`;
      } else {
        _o.headers.token = sessionStorage.getItem('token');
      }
    }
  }

  const options = {
    method,
    url,
    headers: _o.headers,
    withCredentials,
  };

  if (queryParams) {
    options.params = queryParams;
  }

  if (postParams) {
    options.data = postParams;
  }

  return axios.create(options).request(options);
}

export default callApi;

import { getLastPrivateMessageDetails } from '../../../../utils/messaging';


export function openChatsList() {
  // Remove all empty conversations from the "Open Chats" tab
  // except for the one that was just added (just opened, if any )
  // Just opened empty conversation will come with the "isNewConversation" flag
  const isAnchor = this.rootStore.loggedInUser.account.anchor;
  let filteredConversations = this.conversations.data.filter((c) => (c.lastMessage !== null || c.lastCustomMessage !== null) || c.isNewConversation);
  if (isAnchor) {
    filteredConversations = this.conversations.data.filter((c) => (c.lastMessage !== null) || (c.lastCustomMessage !== null && c.lastCustomMessage.details && c.lastCustomMessage.details.type === 'tippingMessage') || c.isNewConversation);
  }

  if (this.searchQuery) {
    filteredConversations = filteredConversations.filter((c) => {
      const recipient = c.partner;
      const regex = new RegExp(this.searchQuery, 'gi');
      if (recipient.custom && recipient.nickname) {
        return regex.test(recipient.nickname);
      }

      return regex.test(recipient.username);

    });
  }

  const mappedConversations = filteredConversations.map((c) => {
    // change those two filter methods to one forEach for optimization
    const recipient = c.partner;
    const me = c.requester;


    const lastMessageDetails = getLastPrivateMessageDetails(c, isAnchor, me, this.activeConversationName);

    return {
      name: c.name,
      lastMessage: lastMessageDetails.lastMessageReceived,
      lastMessageTimestamp: lastMessageDetails.lastMessageReceivedTimestamp,
      custom: c.custom,
      user: recipient,
      me,
      online: this.usersPresence.get(recipient.username.toLowerCase()),
      active: c.name === this.activeConversationName,
      hasUnreadMessages: lastMessageDetails.messageRead,
      punished: lastMessageDetails?.punished,
      metadata: c?.metadata,
      details: lastMessageDetails?.details,
      partner: c?.partner,
      messageType: c?.lastMessage?.messageType,
    };
  });

  if (isAnchor) {
    return mappedConversations.sort((a, b) => a.lastMessageTimestamp > b.lastMessageTimestamp ? -1 : 1);
  }

  return mappedConversations;
}

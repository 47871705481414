/**
 * @param String
 *
 * Find person's name initials
 *
 * If called with string with only one word, one letter is returned
 * If called with string with two or more words, first letters of first two words are returned
 *
 * Example:
 * 'username' => u
 * 'username anotherword' => ua
 * 'one two three' => ot
 *
 *
 * @returns String
 * */
export default (text) => {
  const components = text.split(' ');

  return components[0][0];
};

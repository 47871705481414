import React from 'react';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import AuthenticationStore from '../../store/AuthenticationStore';
import { doLogin } from '../../utils/auth';
import { observer } from 'mobx-react';

@observer
class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
    };
  }

  handleChange = (name) => (e) => {
    if (name === 'rememberMe') {
      AuthenticationStore.setRememberMe(e.target.checked);
    } else {
      this.setState({ [name]: e.target.value });
    }
  };

  handleSubmit = (e) => {
    const { username, password } = this.state;
    const { rememberMe } = AuthenticationStore;
    const trimmedUsername = username && username.trim();
    const trimmedPassword = password && password.trim();
    e.preventDefault();

    this.requestRecaptcha().
      then((recaptcha) => {
        const data = `username=${trimmedUsername}&password=${encodeURIComponent(trimmedPassword)}&rememberMe=${rememberMe}&recaptcha=${recaptcha}`;

        doLogin(data).
          then((user) => {
            this.props.onSubmitted(user);
          }).
          catch((err) => {
            this.props.onError(err);
          });
      });
  };

  requestRecaptcha = () => {
    const { googleReCaptchaProps: { executeRecaptcha } } = this.props;

    return executeRecaptcha('login');
  };

  render() {
    const { username, password } = this.state;
    const { rememberMe } = AuthenticationStore;

    return (
      <div className="nakednews-form">
        <div className="nakednews-form-error" />
        <form
          acceptCharset="UTF-8"
          className="formtastic form-horizontal customer login-form"
          data-remote="true"
          id="modal_new_customer"
          noValidate="novalidate"
          onSubmit={this.handleSubmit}
        >
          <input defaultValue="✓" name="utf8" type="hidden" />
          <div
            className="hidden input optional form-group"
            id="modal_customer__tgt_url_input"
          >
            <label
              className="col-lg-3 control-label"
              htmlFor="modal_customer__tgt_url"
            >
              Tgt url
            </label>
            <div className="col-lg-12">
              <input
                className="form-control"
                defaultValue="/"
                id="modal_customer__tgt_url"
                name="customer[_tgt_url]"
                type="hidden"
              />
            </div>
          </div>
          <div
            className="string input optional stringish form-group"
            id="modal_customer_username_input"
          >
            <div className="col-lg-12">
              <input
                autoCapitalize="none"
                className="form-input username"
                id="modal_customer_username"
                name="customer[username]"
                placeholder="Username / Email"
                type="text"
                value={username}
                onChange={this.handleChange('username')}
              />
            </div>
          </div>
          <div
            className="password input optional stringish form-group"
            id="modal_customer_password_input"
          >
            <div className="col-lg-12">
              <input
                className="form-input password"
                id="modal_customer_password"
                name="customer[password]"
                placeholder="Password"
                type="password"
                value={password}
                onChange={this.handleChange('password')}
              />
            </div>
          </div>
          <div
            className="boolean input optional form-group"
            id="modal_customer_remember_me_input"
          >
            <input
              defaultValue={0}
              name="customer[remember_me]"
              type="hidden"
            />
            <div className="col-lg-12">
              <div className="checkbox">
                <label
                  className="form-switch"
                  htmlFor="modal_customer_remember_me"
                >
                  <input
                    checked={rememberMe}
                    id="modal_customer_remember_me"
                    name="customer[remember_me]"
                    type="checkbox"
                    onChange={this.handleChange(
                      'rememberMe'
                    )}
                  />
                  <i />
                  Remember Me
                </label>
              </div>
              <div className="form-group">
                <div className="col-lg-12">
                  <button
                    className="btn btn-black action-button"
                    id="modal_customer_submit_button"
                    name="button"
                    type="submit"
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withGoogleReCaptcha(LoginForm);

import React from 'react';
import {
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';


const useStyles = makeStyles((theme) => {
  return {
    container: {
      backgroundColor: 'rgb(171, 201, 236)',
      borderBottom: `1px solid ${theme.palette.common.white}`,
      color: theme.palette.common.black,
      position: 'relative',
      zIndex: 99999,
    },
    messageWrapper: {
      margin: '0 auto',
      width: 1160,
      padding: 10,
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1, 4),
        width: '100%',
      },
    },
    title: {
      fontSize: 18,
      fontWeight: 700,
    },
    message: { fontSize: 14 },
    dontShow: {
      fontSize: 14,
      textDecoration: 'underline',
      cursor: 'pointer',
      marginLeft: theme.spacing(2),
    },
  };
});

const EmailWhitelistMessage = ({ dontShow, removeEmailWhitelistMessage }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.messageWrapper}>
        <Typography className={classes.title}>Don't miss the news of our anchors!</Typography>
        <Typography className={classes.message}>
          It's possible that you are missing the news of our anchors, the opportunity to join the best Party Shows or that you aren't receiving emails that are important to you! Please follow the steps below so that this doesn't happen again!
        </Typography>
        <Box alignItems="center" display="flex" mt={2}>
          <Link className="btn btn-success" to="/faq#13" onClick={removeEmailWhitelistMessage}>
            Let's solve this!
          </Link>
          <Typography className={classes.dontShow} onClick={dontShow}>
            Don't show this again
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default EmailWhitelistMessage;

export function getNumOfConversationsWithUnreadMessages() {
  const { session } = this.rootStore.privateMessagingStore.globalPresenceSession;
  if (session) {
    session.getNumberOfUnreadThreads((error, data) => {
      if (!error) {
        this.numOfConversationsWithUnreadMessages = data.conversationsCount;
      } else {
        console.error(error);
      }
    });
  }
}

import { action, observable } from 'mobx';

import { PROMO_CODES } from '../constants/promoCodes';


class CampaignStore {

  @observable currentCampaign = {};
  @observable campaignToShowOnHomePage = {};
  @observable allActiveCampaigns = [];

  getCampaignCheckoutUrl = () => '/account/purchase';

  getHomePageCampaignForUser = (user, allActiveCampaigns) => {
    const campaign = allActiveCampaigns?.find((campaign) => campaign?.affiliateCode === PROMO_CODES.NAKEDNEWS25);
    if (campaign?.name) {
      campaign.checkoutUrl = this.getCampaignCheckoutUrl(campaign);
    }

    return campaign;
  };

  findCampaignByCode = (affiliateCode) => this.allActiveCampaigns?.find((c) => c.affiliateCode === affiliateCode);


  @action setCampaignByPage = (currentPathName, anchorToSubscribe) => {
    if (currentPathName.includes('/account/purchase') && !anchorToSubscribe) {
      this.currentCampaign = this.findCampaignByCode(PROMO_CODES.NAKEDNEWS25);
    }

    if (currentPathName.includes('/account/purchase') && currentPathName.includes(PROMO_CODES.EVENTS_24)) {
      this.currentCampaign = this.findCampaignByCode(PROMO_CODES.EVENTS_24) || {};
    }

    if (currentPathName.includes('/account/purchase') && currentPathName.includes(PROMO_CODES.CAMS_BB_50)) {
      this.currentCampaign = this.findCampaignByCode(PROMO_CODES.CAMS_BB_50) || {};
    }
    if (currentPathName.includes('/account/purchase') && currentPathName.includes(PROMO_CODES.PORN_DUDE_50)) {
      this.currentCampaign = this.findCampaignByCode(PROMO_CODES.PORN_DUDE_50) || {};
    }

    if (currentPathName.includes('/lifetime-promo-live')) {
      this.currentCampaign = this.allActiveCampaigns?.find((c) => c.affiliateCode?.includes('NNLIVEMRSK')) || {};
    }

    if (currentPathName.includes('/lifetime-promo-playboy')) {
      this.currentCampaign = this.allActiveCampaigns?.find((c) => c.affiliateCode?.includes(PROMO_CODES.LIFETIMEPBNOV24)) || {};
    }
    if (currentPathName.includes('/lifetime-promo') && currentPathName.includes(PROMO_CODES.NNLIVEMRSK6)) {
      this.currentCampaign = this.allActiveCampaigns?.find((c) => c.affiliateCode?.includes(PROMO_CODES.NNLIVEMRSK6)) || {};
    }
    if (currentPathName.includes('/lifetime-promo') && currentPathName.includes(PROMO_CODES.CAMS_BB)) {
      this.currentCampaign = this.allActiveCampaigns?.find((c) => c.affiliateCode === PROMO_CODES.CAMS_BB) || {};
    }
    if (currentPathName.includes('/lifetime-promo') && currentPathName.includes(PROMO_CODES.PORN_DUDE)) {
      this.currentCampaign = this.allActiveCampaigns?.find((c) => c.affiliateCode === PROMO_CODES.PORN_DUDE) || {};
    }

    if (currentPathName === '/promo/asn-awards') {
      this.currentCampaign = this.findCampaignByCode(PROMO_CODES.ASNAW22) || {};
    }

    if (!this.currentCampaign) {
      this.currentCampaign = {};
    }
  };

  @action setCurrentCampaign = (user, allActiveCampaigns) => {
    this.campaignToShowOnHomePage = this.getHomePageCampaignForUser(user, allActiveCampaigns);
    this.allActiveCampaigns = allActiveCampaigns;
  };
}

export default new CampaignStore();

import React from 'react';

import RootStore from '../store/RootStore';


export const UserContext = React.createContext({
  username: '',
  external: false,
  activeSubscription: true,
});

export const RootStoreContext = React.createContext(RootStore);

export async function search(query) {
  if (this.rootStore.loggedInUser.account.anchor) {
    this.searchQuery = query;
  }
}

export function changeActiveTab(tab) {
  if (this.rootStore.loggedInUser.account.anchor) {
    if (tab.role === 'both' || tab.role === 'anchor') {
      this.sidebarTabs.active = tab.tab;
    }
  } else if (tab.role === 'both' || tab.role === 'fan') {
    this.sidebarTabs.active = tab.tab;
  }
}

export function openMobileSidebar() {
  this.sidebarMobile = true;
}

export function closeMobileSidebar() {
  this.sidebarMobile = false;
}

export function resetConversationSession() {
  if (this.conversationSession.session !== null) {
    this.conversationSession.session.leaveConversation();
    this.conversationSession.loading = false;
    this.conversationSession.error = null;
    this.conversationSession.session = null;
    this.conversationSession.token = '';
    this.activeConversationName = '';
  }
}

export function setInputAreaHeight(height) {
  this.inputAreaHeight = height;
}

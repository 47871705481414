import React, { useEffect, useState } from 'react';

import FlipComponent from './FlipComponent/FlipComponent';
import './CountdownTimerComponent.css';


const CountdownTimerComponent = ({ activeCampaign }) => {

  const { secondsLeft } = activeCampaign;
  const [secondsLeftState, setSecondsLeftState] = useState(secondsLeft);

  useEffect(() => {
    const interval = setInterval(() => {
      setSecondsLeftState((oldSecondsLeftState) => {
        if (oldSecondsLeftState > 0) {
          return oldSecondsLeftState - 1;
        }
        clearInterval(interval);

        return oldSecondsLeftState;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [setSecondsLeftState]);

  const hours = Math.trunc(secondsLeftState / (60 * 60));
  const minutes = Math.trunc((secondsLeftState - hours * 60 * 60) / 60);
  const seconds = (secondsLeftState - hours * 60 * 60) % 60;

  let hoursString = hours.toString();
  hoursString = hoursString.length > 1 ? hoursString : `0${hoursString}`;
  let minutesString = minutes.toString();
  minutesString = minutesString.length > 1 ? minutesString : `0${minutesString}`;
  let secondsString = seconds.toString();
  secondsString = secondsString.length > 1 ? secondsString : `0${secondsString}`;

  const gotToPurchase = () => {
    window.location.href = '/account/purchase';
  };

  return (
    <div className="countDownTimer" onClick={gotToPurchase}>
      <div className="countDownTimer-item">
        <div className="countDownTimer-item-label">Hours</div>
        <div className="countDownTimer-item-value">
          <FlipComponent value={hoursString.charAt(0)} />
          <FlipComponent value={hoursString.charAt(1)} />
        </div>
      </div>
      <div className="countDownTimer-item countDownTimer-item-dot-wrap">
        <div className="countDownTimer-item-dot" />
        <div className="countDownTimer-item-dot" />
      </div>
      <div className="countDownTimer-item">
        <div className="countDownTimer-item-label">Minutes</div>
        <div className="countDownTimer-item-value">
          <FlipComponent value={minutesString.charAt(0)} />
          <FlipComponent value={minutesString.charAt(1)} />
        </div>
      </div>
      <div className="countDownTimer-item countDownTimer-item-dot-wrap">
        <div className="countDownTimer-item-dot" />
        <div className="countDownTimer-item-dot" />
      </div>
      <div className="countDownTimer-item">
        <div className="countDownTimer-item-label">Seconds</div>
        <div className="countDownTimer-item-value">
          <FlipComponent value={secondsString.charAt(0)} />
          <FlipComponent value={secondsString.charAt(1)} />
        </div>
      </div>
    </div>
  );
};

export default CountdownTimerComponent;

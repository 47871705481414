import React from 'react';
import { isMobile } from 'react-device-detect';

import { isNudeDomain } from '../../utils/helpers';
import { LIST_OF_PATHS_TO_HIDE_DEFAULT_HEADER_AND_FOOTER } from '../../constants/Constants';


const NakedNewsFooter = React.lazy(() => import('./NakedNewsFooter'));
const NudeNewsFooter = React.lazy(() => import('./NudeNewsFooter'));


const Footer = ({ location }) => {
  if (LIST_OF_PATHS_TO_HIDE_DEFAULT_HEADER_AND_FOOTER.includes(location?.pathname) ||
    ((location.pathname === '/dashboard/broadcast' ||
      location.pathname === '/chat') &&
      isMobile
    )) {
    return null;
  }

  const FooterComponent = isNudeDomain ? NudeNewsFooter : NakedNewsFooter;

  return (
    <React.Suspense fallback={null}>
      <FooterComponent />
    </React.Suspense>
  );
};

export default Footer;

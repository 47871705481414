import React, { useContext, useRef } from 'react';
import qs from 'qs';

import { UserContext } from '../../context/index';
import LoginForm from '../../components/generalComponents/LoginForm';
import { OAUTH_URL } from '../../constants/apiUrls/apiUrls';


function OAuthLogin({ location }) {
  const user = useContext(UserContext);
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const buttonRef = useRef();

  const handleAuth = () => {
    buttonRef.current.click();
  };

  return (
    <div className="oauth-login-container">
      {!user.authenticated ? (
        <>
          <LoginForm onError={() => { }} onSubmitted={handleAuth} />
          <form action={OAUTH_URL} method="GET">
            {Object.keys(params).map((key) => <input name={key} type="hidden" value={params[key]} />)}
            <button ref={buttonRef} style={{ display: 'none' }} type="submit">Submit</button>
          </form>
        </>
      ) : (
        <p>You are logged in</p>
      )}
    </div>
  );
}

export default OAuthLogin;

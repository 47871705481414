import http from '../../services/http';
import forResponse from '../../utils/forResponse';
import { ANCHOR_LIST, ANCHOR_BROADCAST_STATUS, FANZONES, ALL_ANCHOR_MEDIA_TOTALS } from '../../constants/apiUrls/apiUrls';


export const getAnchors = () => forResponse(http.get(ANCHOR_LIST));

export const checkAnchorsBroadcastStatus = () => forResponse(http.get(ANCHOR_BROADCAST_STATUS));

export const checkFanZoneStatus = () => forResponse(http.get(FANZONES));

export const fetchGalleriesAndVideosCountForAllAnchors = () => forResponse(http.get(ALL_ANCHOR_MEDIA_TOTALS));


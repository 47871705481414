import { observable, action } from 'mobx';

export const NOTIFICATION_SEVERITY = {
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  INFO: 'INFO',
  ERROR: 'ERROR'
}

class NotificationStore {
  @observable notificationMessage = '';
  @observable notificationSeverity = '';

  // Don't use it directly, use success, error, warning and info actions instead and just provide a message
  @action notify(message, severity) {
    this.notificationMessage = message;
    this.notificationSeverity = severity;
  }

  @action success(message) {
    this.notify(message, NOTIFICATION_SEVERITY.SUCCESS);
  }

  @action error(message) {
    this.notify(message, NOTIFICATION_SEVERITY.ERROR);
  }

  @action warning(message) {
    this.notify(message, NOTIFICATION_SEVERITY.WARNING);
  }

  @action info(message) {
    this.notify(message, NOTIFICATION_SEVERITY.INFO);
  }

  @action dismissNotification() {
    this.notificationMessage = '';
    this.notificationSeverity = '';
  }
}

export default new NotificationStore();

import { action, observable } from 'mobx';


class AuthenticationStore {
  @observable rememberMe = true;

  @action setRememberMe = (value) => {
    this.rememberMe = value;
  };

}

export default new AuthenticationStore();

import React from 'react';
import { Close } from '@material-ui/icons';
import './UpgradeAccountHeader.css';


const UpgradeAccountHeader = ({ isMobile, closePremiumUpgradeBar }) => (
  <div id="premium-upgrade-bar">
    {isMobile ? (
      <>
        <div className="container">
          <div className="pub-mobile">
            <p style={{ display: 'inline-block' }}>You have limited access to Naked News</p>
            <div className="mobile-pub-cta">
              <a className="btn" href="/account/purchase">
                <b>Upgrade now</b>
              </a>
            </div>
          </div>
        </div>
        <span className="mobile-pub-close" onClick={closePremiumUpgradeBar}>
          <Close fontSize="large" />
        </span>
      </>
    ) : (
      <div className="pub-desktop" style={{ marginLeft: '30px', marginRight: '20px' }}>
        <div className="pub-info">
          <p className="h2">
            You have limited access to Naked News. <span><a href="/account/purchase">UPGRADE NOW</a></span> to see everything!
          </p>
          <div className="pub-pricing">
            <table>
              <tbody>
                <tr className="price">
                  <td className="dollars" rowSpan="2">
                    $8
                  </td>
                  <td className="cents">
                    .00
                  </td>
                </tr>
                <tr>
                  <td className="period">
                    <b className="units">/Month</b>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="2">
                    <span className="notation pull-right">(Billed Yearly)</span>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className="pub-cta">
            <a className="btn" href="/account/purchase">
              <b>Upgrade now</b>
            </a>
            <p
              className="pub-close"
              onClick={closePremiumUpgradeBar}
            >
              no thanks <Close />
            </p>
          </div>
        </div>
      </div>
    )}
  </div>
);

export default UpgradeAccountHeader;

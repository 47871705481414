import React from 'react';

import styles from '../../css/CookieUsage.module.css';


const CookieConsent = ({ onAccept }) => (
  <div className={styles.siteNotificationCookieUsage} data-name="cookie_usage">
    <div className={styles.cookieContainer}>
      <h3>Cookie Notice</h3>
      <p>
        We use cookies to understand how you use our site and to improve
        your experience. This includes personalizing content and
        advertising. To learn more,
        <a className={styles.link} href="/cookie-policy"> click here</a>. By
        continuing to use our site, you accept our
        <a className={styles.link} href="/cookie-policy"> use of cookies</a>, revised
        <a className={styles.link} href="/privacy_policy"> Privacy Policy</a>, and
        <a className={styles.link} href="/terms_of_service"> Terms of Use</a>.
      </p>
      <a className={`${styles.btn} ${styles.btnSuccess}`} href="/consent?n=cookie_usage" onClick={onAccept}>
        Accept
      </a>
    </div>
  </div>
);

export default CookieConsent;

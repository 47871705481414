import { action, observable, runInAction } from 'mobx';

import * as LiveBroadcastServices from '../services/LiveBroadcastServices';
import AnchorStore from './AnchorStore';
import GroupShowStore from './GroupShowStore';
import callApi from '../utils/callApi';
import { ANCHOR_SERVICES_URL } from '../constants/apiUrls/apiUrls';
import { ANCHOR_SERVICES } from '../constants/anchorServices';


const BROADCAST_TYPES = {
  STARTING_BROADCAST: 'anchorStartingBroadcast',
  STARTING_SPOTLIGHT: 'temptationStartedSpotlighting',
  JOINED_GROUP_SHOW: 'temptationJoinedVPS',
  STOPPING_BROADCAST: 'anchorStoppingBroadcast',
  TEMPTATION_STOPPING_SPOTLIGHT: 'temptationStoppedSpotlighting',
};

const DEFAULT_STREAM_DATA = {
  provider: 'CAM4_STREAMING_SAAS',
  status: 'IN_PROGRESS',
  streamViews: [{
    accessToken: null,
    protocol: 'WebRTC',
    url: null,
    username: null,
  }],
  streamingSourceType: 'TEMPTED',
  streamDataWasFetched: false,
};

class LiveBroadcastStore {
  @observable userCanAccessSpecialEvent = false;
  @observable anchorId = null;
  @observable anchorName = null;
  @observable anchorNickname = null;
  @observable streamId = null;
  @observable isSpotlight = false;
  @observable isSpotlighing = false;
  @observable spotlightedByAnchorNickname = null;
  @observable spotlighedAnchorNickname = null;
  @observable spotlighedAnchorUsername = null;
  @observable spotlighterClosedSession = false;
  @observable subscribedToOneOfTheAnchors = false;
  @observable chatOnline = false;
  @observable chatDisabled = false;
  @observable streamStarted = false;
  @observable streamEventNotification = null;
  @observable anchorVideoUrl = null;
  @observable lastStreamId = null;
  @observable chatService = null;
  @observable chatServiceTem = null;
  @observable guestUser = '';
  @observable streamService = null;
  @observable broadcasterClosedSession = false;
  @observable spotlights = [];
  @observable broadcastingTemptation = {
    id: null,
    nickname: null,
    username: null,
  };
  @observable isPunished = false;
  @observable listOfPunishInStream = [];
  @observable canWriteWhenChatIsPublic = true;

  @action setBroadcasterClosedSession = (value) => {
    this.broadcasterClosedSession = value;
  };

  @action setUserCanAccessSpecialEvent = (bool) => {
    this.userCanAccessSpecialEvent = bool;
  };

  @action setAnchorName = (value) => {
    this.anchorName = value;
  }

  @action setIsSpotlight = (value) => {
    this.isSpotlight = value;
  };

  @action setSpotlights = (value) => {
    this.spotlights = value;
  };

  @action setSpotlightedByAnchorNickname = (value) => {
    this.spotlightedByAnchorNickname = value;
  };

  @action setSubscribedToOneOfTheAnchors = (value) => {
    this.subscribedToOneOfTheAnchors = value;
  };

  @action setStreamId = (streamId) => {
    this.streamId = streamId;
  };

  @action updateSfwStreamFlag = (nsfw) => {
    const payload = {
      id: this.streamId,
      sfw: !nsfw,
    };

    LiveBroadcastServices.updateSfwStreamFlag(payload);
  };

  @action setSpotlighterClosedSession = (value) => {
    this.spotlighterClosedSession = value;
  };

  @action setBroadcastingTemptation = (value) => {
    this.broadcastingTemptation = value;
  };

  @action setChatOnline = (value) => {
    this.chatOnline = value;
  };

  @action setChatDisabled = (value) => {
    this.chatDisabled = value;
  };

  @action setStreamStarted = (value) => {
    this.streamStarted = value;
  };

  @action handlePunish = (anchorNickname) => {
    const name = anchorNickname || this.anchorNickname;
    this.fetchAnchorServicesUrls(name);
  };

  @action setListOfPunishInStream = (value) => {
    this.listOfPunishInStream = value || [];
  };

  @action removeFromListOfPunishInStream = (idToRemove) => {
    if (this.listOfPunishInStream?.length) {
      this.listOfPunishInStream = this.listOfPunishInStream.filter((temptation) => temptation?.temptationId !== idToRemove);
    }
  };

  @action pushToListOfPunishInStream = (value) => {
    if (!this.listOfPunishInStream) {
      this.listOfPunishInStream = [value];
      return;
    }
    this.listOfPunishInStream.push(value);
  };

  setStreamData = (chatService, chatServiceTem, streamService, guestUser, isSpotlighing) => {
    this.listOfPunishInStream = chatService?.custom?.punishedByTemptations;
    this.isPunished = chatService?.custom?.isPunishedByBroadcaster;
    this.canWriteWhenChatIsPublic = chatService?.canWriteWhenRoomIsPublic;
    this.chatService = chatService;
    this.chatServiceTem = chatServiceTem;
    this.streamService = streamService;
    this.guestUser = guestUser;
  };

  fetchAnchorServicesUrls = (anchorName) => {
    this.anchorNickname = anchorName;
    const url = encodeURI(ANCHOR_SERVICES_URL.replace('{anchorName}', anchorName));
    if (!anchorName) {
      return null;
    }
    callApi(url, 'GET', undefined, undefined, undefined, true, true, true).
      then((res) => {
        const guestUser = res.data.username;
        const { services } = res.data;
        const streamService = services.find((service) => service.name === ANCHOR_SERVICES.STREAM);
        const chatService = services.find((service) => service.name === ANCHOR_SERVICES.CHAT);
        this.listOfPunishInStream = chatService?.custom?.punishedByTemptations;
        const cam4StreamService = services.find((service) => service.name !== ANCHOR_SERVICES.CHAT);
        const chatServiceTem = services.find((service) => service.name === ANCHOR_SERVICES.CHAT_TEMPTED);
        this.setStreamData(chatService, chatServiceTem, streamService, guestUser);
        if (res.data.profileStatus === 'IS_BROADCASTING_ON_CAM4') {
          DEFAULT_STREAM_DATA.streamViews[0].url = cam4StreamService?.url;
          DEFAULT_STREAM_DATA.streamViews[0].username = cam4StreamService?.serviceUsername;
          this.anchorVideoUrl = DEFAULT_STREAM_DATA;
          this.streamStarted = true;
        }
        if (res.data.profileStatus === 'IS_SPOTLIGHTING') {
          this.isSpotlighing = true;
          this.spotlighedAnchorUsername = res.data.spotlightedTemptationUsername;
          this.spotlighedAnchorNickname = res.data.spotlightedTemptationNickname;
          this.streamStarted = true;
          this.fetchSpotlightedAnchorServices(res.data.spotlightedTemptationUsername, true);
        } else {
          this.fetchAnchorActiveStream(anchorName, streamService);
        }
      }).
      catch((err) => {
        console.error(err);
      });
  };

  fetchSpotlightedAnchorServices = (anchorName, isSpotlighting) => {
    const url = encodeURI(ANCHOR_SERVICES_URL.replace('{anchorName}', anchorName));
    callApi(url, 'GET', undefined, undefined, undefined, true, true, true).
      then((res) => {
        const guestUser = res.data.username;
        const { services } = res.data;
        const streamService = services.find((service) => service.name === ANCHOR_SERVICES.STREAM);
        const chatService = services.find((service) => service.name === ANCHOR_SERVICES.CHAT);
        const chatServiceTem = services.find((service) => service.name === ANCHOR_SERVICES.CHAT_TEMPTED);
        this.setStreamData(chatService, chatServiceTem, streamService, guestUser, isSpotlighting);
        this.fetchAnchorActiveStream(anchorName, streamService);
      }).
      catch((err) => {
        console.error(err);
      });
  };

  fetchAnchorActiveStream = (anchorUsername, streamService) => {
    const url = encodeURI(`${streamService.url}/streams/${anchorUsername}/active`);
    callApi(url, 'get', {}, {}, { 'Authorization': `Bearer ${streamService.token}` }).
      then((res) => {
        if (res.data?.length > 0) {
          if (!res.data[0].inGroupShow) {
            if (res.data[0].provider === 'WOWZA') {
              AnchorStore.setIsAnchorLive(true);
              this.anchorVideoUrl = res.data[0];
              this.streamStarted = true;
            }

            if (res.data[0].provider === 'STREAMING_SAAS') {
              this.anchorVideoUrl = res.data[0];
              this.streamStarted = true;
              AnchorStore.setIsAnchorLive(true);
            }

            if (res.data[0].provider === 'CAM4_STREAMING_SAAS') {
              this.anchorVideoUrl = res.data[0];
              this.streamStarted = true;
              AnchorStore.setIsAnchorLive(true);
            }
          } else {
            GroupShowStore.getAnchorServices(anchorUsername);
          }
        } else {
          GroupShowStore.getAnchorServices(anchorUsername);
          AnchorStore.setIsAnchorLive(false);
        }
      }).
      catch((error) => {
        console.error(error);
      });
  };

  @action setStreamEventNotification = (event) => {
    this.streamEventNotification = event;
    const { anchorId, temptationId, spotlightedTemptationId, type } = event?.content || {};

    const notificationIncludesCurrentAnchor = (this.anchorId === anchorId) ||
      (temptationId === this.anchorId) ||
      (spotlightedTemptationId === this.anchorId);
    const broadCastStartTypes = [
      BROADCAST_TYPES.JOINED_GROUP_SHOW,
      BROADCAST_TYPES.STARTING_BROADCAST,
      BROADCAST_TYPES.STARTING_SPOTLIGHT,
    ];
    if (notificationIncludesCurrentAnchor && broadCastStartTypes.includes(type)) {
      this.fetchAnchorServicesUrls(this.anchorName);
    }

    if (temptationId === this.anchorId && (type === BROADCAST_TYPES.STARTING_SPOTLIGHT)) {
      this.isSpotlighing = true;
    }
    if (spotlightedTemptationId === this.anchorId && (type === BROADCAST_TYPES.STARTING_SPOTLIGHT)) {
      this.isSpotlight = true;
    }

    if (temptationId === this.anchorId &&
      (type === BROADCAST_TYPES.TEMPTATION_STOPPING_SPOTLIGHT)) {
      this.spotlighterClosedSession = true;
      this.lastStreamId = this.anchorVideoUrl?.id;
    }
    if (spotlightedTemptationId === this.anchorId && (this.spotlights?.length < 1) &&
      (type === BROADCAST_TYPES.TEMPTATION_STOPPING_SPOTLIGHT)) {
      this.isSpotlight = false;
      this.isSpotlighing = false;
      this.fetchAnchorServicesUrls(this.anchorName);
    }
  };

  @action setAnchorId = (id) => {
    this.anchorId = id;
  };

  @action setAnchorVideoUrl = (streamData) => {
    this.anchorVideoUrl = streamData;
  };

  @action setLastStreamId = (integer) => {
    this.lastStreamId = integer;
  };

  @action reset = () => {
    this.streamId = null;
    this.isSpotlight = false;
    this.isSpotlighing = false;
    this.spotlightedByAnchorNickname = null;
    this.spotlighedAnchorNickname = null;
    this.spotlighedAnchorUsername = null;
    this.subscribedToOneOfTheAnchors = false;
    this.chatOnline = false;
    this.chatDisabled = false;
    this.streamStarted = false;
    this.streamEventNotification = null;
    this.anchorVideoUrl = null;
    this.chatService = null;
    this.chatServiceTem = null;
    this.streamService = null;
    this.broadcasterClosedSession = false;
    this.spotlighterClosedSession = false;
    this.isPunished = false;
    this.listOfPunishInStream = [];
    this.anchorNickname = null;
  };
}

export default new LiveBroadcastStore();

import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Close } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import Modal from 'simple-react-modal';
import LoginModalStore from '../../../store/LoginModalStore';
import { observer } from 'mobx-react';
import styles from '../FastPassLogin/styles/styles.module.css';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { debounce } from '@material-ui/core';
import SMSCodeLogin from './SMSCodeLogin';
import ConfirmationComponent from './ConfirmationComponent';
import isEmail from 'validator/lib/isEmail';
import Loader from 'react-simple-loading';


const MESSAGES = {
  DEFAULT: 'Enjoy hassle-free access! Simply provide your email to receive a secure access link or enter your phone number to get a one-time code via SMS.',
  PHONE: 'We\'ve sent a security code to your device.',
  EMAIL: 'An Email has been sent to {email} with a link that will enable you to log in.',
  USERNAME: 'An email has been sent to the email associated with username {username}',
}

const isPhoneNumber = (input) => {
  const phoneRegex = /^\+?[1-9]\d{1,14}$/; // Simplified E.164 regex
  return phoneRegex.test(input.replace(/\s/g, ''));
};


function FastPassLoginModal(props) {
  const { isOpen } = props;
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [message, setMessage] = useState(MESSAGES.DEFAULT);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [isPhone, setIsPhone] = useState(false);
  const [isInputEmail, setIsInputEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [countdown, setCountdown] = useState(120);
  const [isValidating, setIsValidating] = useState(false);
  const intervalRef = useRef(null);
  const phoneInputRef = useRef(null);

  const closeModal = useCallback(() => {
    LoginModalStore.setShouldShowModal(false);
    LoginModalStore.setShowFastPassModal(false);
  }, []);

  const handleCredentialLoginButtonClick = useCallback(() => {
    LoginModalStore.setShowFastPassModal(false);
    LoginModalStore.setShouldShowModal(true);
  }, []);

  const debouncedCheckInput = useCallback(
    debounce((value) => {
      const phoneCheck = isPhoneNumber(value);
      const emailCheck = isEmail(value);
      setIsPhone(phoneCheck);
      setIsInputEmail(emailCheck);
      if ((phoneCheck && value?.length > 6) || emailCheck) {
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
      }
      setIsValidating(false);
    }, 200),
    []
  );

  const handleInputChange = useCallback((value, country, e, formattedValue) => {
    setIsValidating(true);
    setInputValue(value.trim());
    formattedValue && setFormattedPhoneNumber(formattedValue?.trim());
    debouncedCheckInput(value.trim());
  }, [debouncedCheckInput]);

  const getSuccessMessage = useCallback(() => {
    if (isPhone) {
      return MESSAGES.PHONE;
    }

    if (isInputEmail) {
      return MESSAGES.EMAIL.replace('{email}', inputValue);
    }

    return MESSAGES.USERNAME.replace('{username}', inputValue);
  }, [isPhone, isInputEmail, inputValue]);

  const onSubmitClick = useCallback((email) => {
    if (isPhone) {
      const phoneNumber = formatInput(inputValue);
      LoginModalStore.createSmsLogin(phoneNumber);
    } else {
      resendEmail();
    }

    setMessage(getSuccessMessage());
    setShowSuccessMessage(true);
    setSubmitted(false);
  }, [getSuccessMessage, inputValue, isPhone]);

  const resetState = useCallback(() => {
    setSubmitted(false);
    setShowSuccessMessage(false);
    setButtonDisabled(true);
  }, [setIsInputEmail, setIsPhone, setShowSuccessMessage, setInputValue]);

  const getField = useCallback(() => {
    if (isInputEmail) {
      return 'email';
    }
    if (isPhone) {
      return 'number';
    }

    return 'nickname';
  }, [isInputEmail, isPhone]);

  const formatInput = useCallback((input) => {
    const inputStartWithPlus = input.startsWith('+');
    if (!isPhone || inputStartWithPlus) {
      return input;
    }

    return '+' + input;
  }, [isPhone]);

  const handleSubmit = useCallback(() => {
    if (isPhone) {
      setSubmitted(true);

      return;
    }

    if (isInputEmail) {
      onSubmitClick(inputValue);
    }
  }, [isPhone, inputValue, isInputEmail]);

  const resendEmail = useCallback(async () => {
    const { success } = await LoginModalStore.createEmailMagicLink(inputValue);
    if (!success) {
      setEmailSent(false);
    } else {
      startCountdown();
      setEmailSent(true);
    }
  }, [inputValue]);

  const startCountdown = () => {
    setCountdown(120); // Start countdown from 120 seconds
    intervalRef.current = setInterval(() => {
      setCountdown(prev => {
        if (prev === 1) {
          clearInterval(intervalRef.current);
          setEmailSent(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    // If a user pastes their number. The formattedPhoneNumber is never set causing the next screen to show an incorrect number
    if (isPhone && !formattedPhoneNumber?.length) {

      if (phoneInputRef?.current?.state?.formattedNumber) {
        setFormattedPhoneNumber(phoneInputRef.current.state.formattedNumber);
      }
      handleInputChange(inputValue, null, null, formattedPhoneNumber);
    }

    return () => clearInterval(intervalRef.current); // Cleanup on unmount
  }, [isPhone, formattedPhoneNumber, inputValue, handleInputChange]);

  return (
    <Modal
      containerClassName={styles.modalContainer}
      closeOnOuterClick={false}
      show={isOpen}
      onClose={closeModal}
    >
      {!submitted &&
        <div className={styles.contentContainer}>
          <button className={styles.closeButton} onClick={closeModal}><Close /></button>
          <h6>Password-Free Access</h6>
          <hr />
          <p>{message}</p>
          {showSuccessMessage && isInputEmail && (
            <>
              <button
                className={styles.resendEmail}
                onClick={resendEmail}
                disabled={emailSent}
              >
                RESEND EMAIL
              </button>
              <p>{emailSent && `You can retry in ${countdown}s`}</p>
              <button
                className={styles.backButton}
                onClick={resetState}
              >
                Wrong Email?
              </button>
            </>
          )}
          {showSuccessMessage && isPhone &&
            <SMSCodeLogin styles={styles} resetState={resetState} formatInput={formatInput} value={inputValue} />
          }
          {!showSuccessMessage &&
            <React.Fragment>
              <div className={styles.inputContainer}>
                {isPhone ? (
                  <PhoneInput
                    ref={phoneInputRef}
                    className={styles.input}
                    autoFormat={true}
                    enableSearch={true}
                    value={inputValue}
                    onChange={handleInputChange}
                    inputProps={{
                      placeholder: 'Enter your phone number',
                      autoFocus: true,
                    }}
                  />
                ) : (
                  <input
                    className={styles.input}
                    type="text"
                    placeholder="Enter your email or phone number"
                    value={inputValue}
                    onChange={(e) => handleInputChange(e.target.value)}
                    maxLength={100}
                  />
                )}
                {isValidating &&
                  <div className={styles.loaderContainer}>
                    <Loader size={'20px'} />
                  </div>
                }
              </div>
              <button
                className={styles.nextButton}
                disabled={buttonDisabled || !inputValue?.length}
                onClick={handleSubmit}
              >
                Next
              </button>
              <button
                className={styles.backButton}
                onClick={handleCredentialLoginButtonClick}
              >
                Back to Login
              </button>
            </React.Fragment >}
          <hr />
          <span>New User? <Link to="/account/purchase" className={styles.link} onClick={closeModal}>Create account</Link></span>
        </div >}
      {
        submitted && isPhone &&
        <ConfirmationComponent
          styles={styles}
          onConfirm={onSubmitClick}
          onReject={resetState}
          fieldToConfirm={getField()}
          dataToConfirm={formatInput(formattedPhoneNumber)}
        />
      }
    </Modal >
  );
}

export default observer(FastPassLoginModal);

import { observable, action, computed, autorun, toJS } from 'mobx';
import { ChatManager } from '@rebelnerds/chat-saas-client';

import * as generalComputedProps from './computed/GeneralComputedProps';
import * as openChatsListComputedProps from './computed/OpenChatsList';
import * as memberCombinedOpenChatsListComputedProps from './computed/MemberCombinedOpenChatsList';
import * as activeConversationProps from './computed/ActiveConversationProps';
import * as messagesComputedProps from './computed/MessagesList';
import * as eventHandlingActions from './actions/EventHandlers';
import * as initializersActions from './actions/Initializers';
import * as createConversationActions from './actions/CreateConversation';
import * as loadersActions from './actions/Loaders';
import * as activateConversationActions from './actions/ActivateConversation';
import * as sendMessageActions from './actions/SendMessage';
import * as generalActions from './actions/GeneralActions';
import * as autoruns from './autoruns/PrivateMessagingAutoruns';


class PrivateMessagingStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.ChatManager = ChatManager;
    autorun(() => {
      const { sidebarTabs: { active } } = this;
      autoruns.whenActiveTabChanges(this, active);
    });
  }

  @observable globalPresenceSession = {
    session: null,
    lastEventData: null,
  };

  @observable sidebarMobile = true;
  @observable conversationSession = {
    loading: false,
    error: null,
    session: null,
    token: '',
  };

  @observable hasUnreadMessages = false;
  @observable globallyPresent = false;
  @observable activeConversationMessages = [];
  @observable activeTab = 'open-chats';
  @observable searchQuery = '';
  @observable sidebarTabs = {
    active: 'open-chats',
    tabs: [
      { tab: 'open-chats', label: 'Open Chats', role: 'both' },
      { tab: 'fans-list', label: 'Subscribers', role: 'anchor' },
      { tab: 'mass-messages', label: 'Message All', role: 'anchor' },
    ],
  };

  @observable usersPresence = new Map();
  @observable conversations = {
    loading: true,
    data: [],
  };

  @observable memberAnchors = [];
  @observable fansList = {
    loading: false,
    data: [],
  };

  @observable activeConversationName = '';
  @observable inputAreaHeight = 0;
  @observable activeConversationHasUnreadMessages = true;

  @observable globalPresenceLocation = null;
  @observable loadingOldMessages = false;
  @observable reachedEndOfMessageHistory = false;
  @observable activeConversationMetadata = {};


  /* ====== Computed Properties ====== */
  @computed get displaySidebar() {
    return generalComputedProps.displaySidebar.call(this);
  }

  @computed get tabsToRender() {
    return generalComputedProps.tabsToRender.call(this);
  }

  @computed get openChatsList() {
    return openChatsListComputedProps.openChatsList.call(this);
  }

  @computed get memberCombinedOpenChatsList() {
    return memberCombinedOpenChatsListComputedProps.memberCombinedOpenChatsList.call(this);
  }

  @computed get fansUsernames() {
    return generalComputedProps.fansUsernames.call(this);
  }

  @computed get activeConversation() {
    return activeConversationProps.activeConversation.call(this);
  }

  @computed get activeConversationHeader() {
    return activeConversationProps.activeConversationHeader.call(this);
  }

  @computed get seenTimestamp() {
    return activeConversationProps.seenTimestamp.call(this);
  }

  @computed get messages() {
    return messagesComputedProps.messages.call(this);
  }

  @computed get myConversationMemberObject() {
    return activeConversationProps.myConversationMemberObject.call(this);
  }

  @computed get isBanned() {
    return activeConversationProps.isBanned.call(this);
  }

  @computed get isConversationActive() {
    return activeConversationProps.isConversationActive.call(this);
  }

  @computed get fanCanSendMessages() {
    return activeConversationProps.fanCanSendMessages.call(this);
  }

  /* =================================== */


  /* ====== Actions ====== */
  @action createGlobalPresenceSession(authenticated, user) {
    return initializersActions.createGlobalPresenceSession.call(this, authenticated, user);
  }

  @action addMeToGlobalPresence() {
    return initializersActions.addMeToGlobalPresence.call(this, this.globalPresenceLocation);
  }

  @action attachGlobalRosterListener() {
    initializersActions.attachGlobalRosterListener.call(this, this.globalPresenceLocation);

  }

  @action attachGlobalEventsListener() {
    initializersActions.attachGlobalEventsListener.call(this);

  }

  @action attachSystemEventsListener() {
    initializersActions.attachSystemEventsListener.call(this);
  }

  @action loadPrivateChatData() {
    loadersActions.loadPrivateChatData.call(this);
  }

  @action usersPresenceEventHandler(event) {
    eventHandlingActions.usersPresenceEventHandler.call(this, event);
  }

  @action systemEventsHandler(event) {
    eventHandlingActions.systemEventsHandler.call(this, event);
  }

  @action globalSessionEventsHandler(event) {
    eventHandlingActions.globalSessionEventsHandler.call(this, event);
  }

  @action changeActiveTab(tab) {
    generalActions.changeActiveTab.call(this, tab);
  }

  @action openMobileSidebar() {
    generalActions.openMobileSidebar.call(this);
  }

  @action closeMobileSidebar() {
    generalActions.closeMobileSidebar.call(this);
  }

  @action resetConversationSession() {
    generalActions.resetConversationSession.call(this);
  }

  @action getFansList() {
    loadersActions.getFansList.call(this);
  }

  @action search(query) {
    generalActions.search.call(this, query);
  }

  @action createConversation(username) {
    createConversationActions.createConversation.call(this, username);
  }

  @action setHasUnreadMessages(hasUnreadMessages) {
    this.hasUnreadMessages = hasUnreadMessages;
  }

  @action activateConversation(payload, hasUnreadMessages, metadata) {
    this.activeConversationMetadata = metadata || {};
    this.activeConversationMetadata.partnerNickname = payload?.nickname;
    activateConversationActions.activateConversation.call(this, payload);
    this.setHasUnreadMessages(hasUnreadMessages);
  }

  @action loadConversationSessionMessages = () => {
    loadersActions.loadConversationSessionMessagesAsync.call(this);
  };

  @action sendMessage(payload, messageMetadata) {
    sendMessageActions.sendMessage.call(this, payload, messageMetadata);
  }

  @action sendAudioMessage(payload) {
    sendMessageActions.sendAudioMessage.call(this, payload);
  }

  @action sendFanAudioMessage(conversationName, payload) {
    return sendMessageActions.sendFanAudioMessage.call(this, conversationName, payload);
  }

  @action onConversationEvents(event) {
    eventHandlingActions.onConversationEvents.call(this, event);
  }

  @action setInputAreaHeight(height) {
    generalActions.setInputAreaHeight.call(this, height);
  }

  @action storeGlobalPresenceLocation(location) {
    this.globalPresenceLocation = location;
  }

  @action updateChatState(content) {
    if (content.conversationName === this.activateConversationName) {
      if (content.type === 'EnableCanWriteMessagesInNonFanConversation') {
        this.activeConversation.custom.fanCanSendMessages = true;
      } else if (content.type === 'DisableCanWriteMessagesInNonFanConversation') {
        this.activateConversation.custom.fanCanSendMessages = false;
      }
    }
  }

  @action loadPreviousConversationSessionMessages = () => {
    loadersActions.loadOldConversationMessages.call(this, toJS(this.activeConversationMessages));
  };

  @action setLoadingOldMessages = (value) => {
    this.loadingOldMessages = value;
  }

  @action setReachedEndOfMessageHistory = (boolean) => {
    this.reachedEndOfMessageHistory = boolean;
  }
}

export default PrivateMessagingStore;

import axios from 'axios';


const instance = axios.create();

instance.interceptors.request.use((config) => {
  const username = sessionStorage.getItem('username');
  const token = sessionStorage.getItem('token');
  const tempted = config.url.indexOf('tempted') > -1;

  if (username) {
    config.headers.username = username;
  }
  if (token && !tempted) {
    config.headers.token = token;
  }
  if (token && tempted) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  if (!config.withCredentials && config.withCredentials !== false) {
    config.withCredentials = true;
  }

  return config;
});

export default instance;

import * as services from '../services/PrivateMessagingServices';


const GLOBAL_PRESENCE_ANCHOR_NODE = 'tt';

export function createGlobalPresenceSession(authenticated, user) {
  return new Promise(async(resolve, reject) => {
    if (!this.globalPresenceSession.session) {
      this.rootStore.loggedInUser = user;
      const { error, data } = await services.getGlobalPresenceToken();
      if (data) {
        this.rootStore.privateMessagingStore.storeGlobalPresenceLocation(data.data.location);

        let username;
        if (authenticated) {
          username = sessionStorage.username;
        } else {
          username = data.data.username;
        }
        this.ChatManager.init(data.data.chatServiceUrl, 'nakednews');
        this.ChatManager.createGlobalPresenceSession(username, data.data.token, (globalPresenceSession, error) => {
          if (!error) {
            this.globalPresenceSession.session = globalPresenceSession;
            resolve();
          } else {
            reject(error);
          }
        });
      } else {
        reject(error);
      }
    } else {
      reject('Session already created!');
    }
  });
}

export function addMeToGlobalPresence(location) {
  if (location) {
    return new Promise((resolve, reject) => {
      this.globalPresenceSession.session.addToGlobalPresenceWithLocation(location, (error) => {
        if (!error) {
          this.globallyPresent = true;
          resolve();
        } else {
          reject();
        }
      });
    });
  }

  return new Promise((resolve, reject) => {
    this.globalPresenceSession.session.addToGlobalPresence(async(error) => {
      if (!error) {
        this.globallyPresent = true;
        resolve();
      } else {
        reject();
      }
    });
  });
}

export function attachGlobalRosterListener(location) {
  if (location && !this.rootStore.loggedInUser.account.anchor) {
    this.globalPresenceSession.session.setGlobalRosterListenerWithLocation(GLOBAL_PRESENCE_ANCHOR_NODE, this.usersPresenceEventHandler.bind(this));

    return;
  }

  if (this.globalPresenceSession.session) {
    this.globalPresenceSession.session.setGlobalRosterListener(this.usersPresenceEventHandler.bind(this));
  }
}

export function attachGlobalEventsListener() {
  if (this.globalPresenceSession.session) {
    this.globalPresenceSession.session.setGlobalEventsListener(this.globalSessionEventsHandler.bind(this));
  }
}

export function attachSystemEventsListener() {
  if (this.globalPresenceSession.session) {
    this.globalPresenceSession.session.setSystemLastEventListner(this.systemEventsHandler.bind(this));
  }
}
